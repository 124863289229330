import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from './i18n'
import VueGeolocation from 'vue-browser-geolocation';
import VueApexCharts from "vue-apexcharts";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueThreeSixty from 'vue-360'
import JsonExcel from "vue-json-excel";
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.component('downloadExcel', JsonExcel);
import 'vue-360/dist/css/style.css'
import './registerServiceWorker'
Vue.use(Notifications)
Vue.use(VueThreeSixty)
Vue.use(VueApexCharts);
Vue.use(DatePicker);
Vue.use(VueSweetalert2);
Vue.component("apexChart", VueApexCharts);
Vue.component("datePicker", DatePicker);
Vue.use(VueGeolocation);
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_SOCKET_URL),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: "places",
  },
});
Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");