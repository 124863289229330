import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "users/";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
import VueJwtDecode from "vue-jwt-decode";

export const accounts = {
  state: {
    users: [],
  },
  getters: {
    allUsers: (state) => state.users,
  },
  mutations: {
    setUser(state, user) {
      state.users = user;
    },
    addUser(state, user) {
      let users = state.users
      users.push(user);
      state.users = users;
    },
    editUser(state, user) {
      let users = state.users;

      let index = users.findIndex((element) => element._id === user._id);
      if (index != -1) {
        users[index].name = user.name;
        users[index].username = user.username;
        users[index].role = user.role;
        users[index].office = user.office;
        users[index].sector = user.sector;

        state.users = users;
      }
    },
    deleteUser(state, id) {
      let users = state.users;

      let filterUser = users.filter((element) => element._id !== id);

      state.users = filterUser;
    },
  },
  actions: {
    async getUsers(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          context.commit("setUser", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    createUsers(context, data) {
      
      return axios
        .post(
          apiURL,
          {
            name: data.name,
            username: data.username,
            password: data.password,
            role: data.role,
            office: data.office,
            sector: data.sector,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("addUser", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteUsers(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log("Delete is successfully");
          context.commit("deleteUser", id);
          return res
        })
        .catch((error) => {
          console.log(error.response);
          return error.response
        });
    },
    editUsers(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
            username: data.username,
            password: data.password,
            role: data.role,
            office: data.office,
            sector: data.sector,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editUser", data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error.response;
        });
    },
    resetPassword(context, data) {
      return axios
        .put(
          apiURL + "resetPassword/" + data.id,
          {
            newPassword: data.newPassword,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log("Reset password successfully");
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    changePassword(context, data) {
      const user = JSON.parse(Cookies.get("user"));
      const userData = VueJwtDecode.decode(user.access_token);
      return axios
        .put(
          apiURL + "changePassword/" + userData._id,
          {
            password: data.password,
            newPassword: data.newPassword,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    activeUser(context, data) {
      
      return axios
        .put(
          apiURL + "active/" + data.id,
          {
            active: data.active,
          },
          { headers: authHeader() }
        )
        .then((res) => {

          //console.log("Active successfully");
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
  },
};
