<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-title>
        <h3>{{ $t("Offices") }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="#2C3A47"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table item-key="id" :headers="headers" :items="getOffices" :search="search">
        <template v-slot:[`header.name`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template v-slot:[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            class="mx-2"
            fab
            x-small
            @click="editOffice(item)"
            outlined
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="pink" fab x-small @click="deleteOffice(item._id)" outlined>
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-results>
          <v-alert :value="true" color="pink" icon="warning" dark>
            Your Search {{ search }} does not exist.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 my-10"
      fixed
      :style="isMobile ? 'bottom: 50px;' : 'bottom: -20px;'"
      fab
      color="info"
      dark
      absolute
      bottom
      right
      @click="
        close();
        opendialog();
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <div id="modal">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title v-if="create" class="headline red darken-2 white--text">
            Add Office
          </v-card-title>
          <v-card-title v-else class="headline red darken-2 white--text">
            Edit Office
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="savePost"
              ref="officeData"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                name="name"
                id="id"
                label="Name"
                hide-details="auto"
                v-model="officeData.name"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="success" text @click="savePost()">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//import axios from "axios";
import { isIOS, isMobile, isAndroid, isSafari, isWinPhone } from "mobile-device-detect";
export default {
  name: "AccountPage",
  components: {},
  computed: {
    getOffices() {
      return this.$store.getters.allOffices;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (!this.loggedIn) {
      this.$router.push("/");
    }
    this.loadOffices();
  },
  data() {
    return {
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      create: true,
      mini: false,
      dialog: false,
      fab: false,
      search: "",
      snackbar: false,
      text: "",
      color: "",
      btnName: "",
      offices: [],
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      officeData: {
        _id: "",
        name: "",
      },
      default: {
        _id: "",
        name: "",
      },
      editedIndex: -1,
    };
  },
  methods: {
    opendialog() {
      this.dialog = true;
      this.create = true;
    },
    loadOffices() {
      this.$store.dispatch("getOffices");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.officeData = Object.assign({}, this.default);
        this.editedIndex = -1;
        this.create = true;
        // this.$refs.officeData.resetValidation();
      }, 300);
    },
    savePost() {
      if (this.editedIndex > -1) {
        this.updateOffice();
      } else {
        this.createOffice();
      }
    },
    createOffice() {
      this.$store.dispatch("createOffices", this.officeData).then(
        () => {
          this.dialog = false;

          this.color = "success";
          this.text = "Office has been successfully saved.";
          this.snackbar = true;
        },
        (error) => {
          this.color = "error";
          this.text = error.response.data.error.message;
          this.snackbar = true;
        }
      );
    },
    editOffice(item) {
      this.editedIndex = this.getOffices.indexOf(item);
      this.officeData = Object.assign({}, item);
      this.create = false;
      this.dialog = true;
    },
    updateOffice() {
      this.$store.dispatch("editOffices", this.officeData).then(
        () => {
          this.close();
          this.color = "info";
          this.text = "Office has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    deleteOffice(id) {
      //console.log(id);
      // this.$swal({
      //   title: "Are you sure?",
      //   text: "You won't be able to revert this!",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Yes, delete it!",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     try {
      this.$store.dispatch("deleteOffices", id);
      //   } catch (error) {
      //     console.log(error);
      //   }
      //   this.$swal("Deleted!", "Your file has been deleted.", "success");
      // }
      // });
    },
  },
};
</script>
