import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "categories/";
import authHeader from "../services/auth-header";
export const categories = {
  state: {
    categories: [],
  },
  getters: {
    allCategories: (state) => state.categories,
  },
  mutations: {
    setCategory(state, category) {
      state.categories = category;
    },
    addCategory(state, category) {
      let categories = state.categories;
      categories.push(category);
      state.categories = categories;
    },
    editCategory(state, category) {
      let categories = state.categories;

      let index = categories.findIndex(
        (element) => element._id === category._id
      );
      if (index != -1) {
        categories[index].name = category.name;
        state.categories = categories;
      }
    },
    deleteCategory(state, id) {
      let categories = state.categories;

      let filterCategory = categories.filter((element) => element._id !== id);

      state.categories = filterCategory;
    },
  },
  actions: {
    async getCategories(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          context.commit("setCategory", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    createCategories(context, data) {
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          context.commit("addCategory", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteCategories(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          context.commit("deleteCategory", id);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    editCategories(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editCategory", data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error.response;
        });
    },
  },
};
