<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemperpage"
      class="elevation-1"
      :loading="loading"
      :options="{ itemsPerPage: this.itemperpage }"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`header.dateStart`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.timeStart`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.dateStop`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.timeStop`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.alarm`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.totaltime`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`item.dateStart`]="{ item }">
        <td>
          {{ new Date(item.datetimeStart).toLocaleDateString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.timeStart`]="{ item }">
        <td>
          {{ new Date(item.datetimeStart).toLocaleTimeString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.dateStop`]="{ item }">
        <td>
          {{ new Date(item.datetimeStop).toLocaleDateString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.timeStop`]="{ item }">
        <td>
          {{ new Date(item.datetimeStop).toLocaleTimeString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.totaltime`]="{ item }">
        <td v-if="item.totaltime != undefined">
          {{ splitTime(item.totaltime) }}
        </td>
      </template>
      
      <template v-slot:[`header.date`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.time`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.description`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.user`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <td>
          {{ new Date(item.datetime).toLocaleDateString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.time`]="{ item }">
        <td>
          {{ new Date(item.datetime).toLocaleTimeString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <td>
          {{ item.user.name }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["items", "headers", "loading", "itemperpage"],
  methods: {
    splitTime(value) {
      let t = value.toString().split(".");

      if (t[1] == undefined) {
        t[1] = 0;
      }
      return parseInt(t[0]) + "h" + parseInt(t[1]) + "min";
      //console.log(t);
    },
  },
};
</script>

<style lang="sass" scoped></style>
