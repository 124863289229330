import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "types/";
import authHeader from "../services/auth-header";
export const types = {
  state: {
    types: [],
  },
  getters: {
    allTypes: (state) => state.types,
  },
  mutations: {
    setType(state, type) {
      state.types = type;
    },
    editType(state, type) {
      let types = state.types;

      let index = types.findIndex((element) => element._id === type._id);
      if (index != -1) {
        types[index].name = type.name;
        types[index].high = type.high;
        types[index].flowPerMin = type.flowPerMin;
        state.types = types;
      }
    },
  },
  actions: {
    async getTypes(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setType", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    editTypes(context, data) {
      return axios
        .put(apiURL + data._id, data, { headers: authHeader() })
        .then((res) => {
          context.commit("editType", data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error;
        });
    },
  },
};
