import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
//import Overview from "@/views/Overview.vue";
import Accounts from "@/views/Accounts.vue";
import Station from "@/views/Station";
import Stations from "@/views/Stations";
import Overview from "@/views/Overview";
import Knowledges from "@/views/Knowledges";
import ResetPassword from "@/views/ResetPassword.vue";
import Sectors from "@/views/Sectors.vue";
import Offices from "@/views/Offices.vue";
import addKnowledges from "@/views/addKnowledges.vue";
import Suggestion from "@/views/Comment.vue";
import showKnowledge from "@/views/showKnowledge.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },

  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
  },
  {
    path: "/stations",
    name: "Stations",
    component: Stations,
  },
  {
    path: "/sectors",
    name: "Sectors",
    component: Sectors,
  },
  {
    path: "/station/status/:id",
    name: "Station",
    component: Station,
  },
  {
    path: "/knowledges/:id",
    name: "KnowledgeShow",
    component: showKnowledge
  },
  {
    path: "/knowledges",
    name: "Knowledges",
    component: Knowledges,
  },
  {
    path: "/knowledges/add",
    name: "KnowledgesAdd",
    component: addKnowledges,
  },
  {
    path: "/suggestion",
    name: "Suggestion",
    component: Suggestion,
  },
  {
    path: "/offices",
    name: "Offices",
    component: Offices,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
