import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "trackings/";
//import authHeader from "../services/auth-header";
export const trackings = {
  state: {
    trackings: [],
  },
  getters: {
    getTracking: (state) => state.trackings,
  },
  mutations: {
    setTracking(state, trackings) {
      state.trackings = trackings;
    },
  },
  actions: {
    async getTracking(context, data) {
      return axios
        .get(apiURL, data)
        .then((res) => {
          console.log(res.data);
          context.commit("setTracking", res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async searchTracking(context, data) {
      console.log(data)
      return axios
        .post(apiURL + "search", data)
        .then((res) => {
          console.log(res.data);
          context.commit("setTracking", res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
