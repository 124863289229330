import Vue from "vue";
import VueI18n from "vue-i18n";
import Formatter from "./formatter";
import en from "./locales/en/translation.json";
import th from "./locales/th/translation.json";
Vue.use(VueI18n);

const locale = "th-TH"; // default locale
const formatter = new Formatter({ locale });

export default new VueI18n({
  locale,
  formatter,
  messages: {
    "en-US": en,
    "th-TH": th,
  },
});
