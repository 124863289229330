import axios from "axios";
import authHeader from "./auth-header";
const apiURL = process.env.VUE_APP_API_URL + "charts/";
class ChartsService {
  async searchCharts(data) {
    return axios
      .post(
        apiURL + "search",
        {
          station: data.station,
          startDate: data.startDate,
          endDate: data.endDate,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        //let obj = res.data.data.chartData.datasets;
        //console.log(res.data);
        let dataChart = {
          chartsOil: this.chartOil(),
          chartsFlow: this.chartFlow(),
        };
        dataChart.chartsFlow.series.push({ name: "Volume of Water", data: [] });
        dataChart.chartsOil.series.push({ name: "Fule", data: [] });
        dataChart.chartsFlow.series[0].data = res.data.dataFlow;
        dataChart.chartsOil.series[0].data = res.data.dataOil;
        dataChart.chartsFlow.chartOptions.xaxis.categories =
          res.data.categoriesFlow;
        dataChart.chartsOil.chartOptions.xaxis.categories =
          res.data.categoriesOil;
        /* for (let i = 0; i < obj.length; i++) {
          let chart = this.chartdefault();
          
          chart.series[0].name = obj[i].name;
          chart.chartOptions.yaxis.title.text = obj[i].name;
          chart.unit = obj[i].unit;
          chart.flowTotalizer = obj[i].flowTotalizer;
          for (let x = 0; x < obj[i].data.length; x++) {
            chart.series[0].data.push({
              y: obj[i].data[x],
              x: obj[i].y[x],
            });
          }
          dataChart.push(chart);
        }
        console.log(dataChart);*/
        return dataChart;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  chartFlow() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 200,
          type: "line",
          zoom: {
            enabled: true,
          },
          toolbar: {
            export: {
              csv: {
                filename: "",
                columnDelimiter: ",",
                headerCategory: "Date time",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toLocaleString("th-TH", {
                    timeZone: "Asia/Bangkok",
                  });
                },
              },
            },
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            show: true,
            format: "dd/MMM/yyyy <br> HH:mm:ss",
          },
          y: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm:ss",
            },
          },
          tooltip: {
            enabled: false,
          },
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Poppins",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        noData: {
          text: "No data available",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
      },
    };
  }

  chartOil() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 200,
          type: "line",

          zoom: {
            enabled: true,
          },
          toolbar: {
            export: {
              csv: {
                filename: "",
                columnDelimiter: ",",
                headerCategory: "Date time",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toLocaleString("th-TH", {
                    timeZone: "Asia/Bangkok",
                  });
                },
              },
            },
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            show: true,
            format: "dd/MMM/yyyy <br> HH:mm:ss",
          },
          y: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm:ss",
            },
          },
          tooltip: {
            enabled: false,
          },
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Poppins",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        noData: {
          text: "No data available",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
      },
    };
  }
}
export default new ChartsService();
