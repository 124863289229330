<template>
  <v-container fluid v-if="!loadingStation">
    <v-row>
      <v-spacer></v-spacer>
      <v-col> </v-col>
    </v-row>
    <v-card color="#F8F8FA" flat>
      <v-tabs
        dense
        color="info"
        right
        v-model="tab"
        background-color="transparent"
      >
        <v-tab color="#F8F8FA" dense v-for="item in items" :key="item.name"
          ><v-icon left> {{ item.icon }} </v-icon>{{ $t(item.name) }}</v-tab
        >
        <v-tabs-items
          v-model="tab"
          style="background-color: transparent"
          class="mt-3"
        >
          <v-tab-item>
            <v-col cols="12">
              <v-card>
                <v-row class="pr-2">
                  <v-col
                    cols="12"
                    xl="3"
                    lg="3"
                    md="12"
                    sm="12"
                    xs="12"
                    class="pt-1 pb-0"
                    align="center"
                  >
                    <p class="ma-2">
                      <v-tooltip
                        bottom
                        v-if="getStation.statusOnline == 'Online'"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="pl-1"
                            color="#078847"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-wifi
                          </v-icon>
                        </template>
                        <span>Online</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="pl-2"
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-wifi-off
                          </v-icon>
                        </template>
                        <span>Offline</span>
                      </v-tooltip>

                      {{ getStation.name }}
                    </p>
                    <iframe
                      id="today"
                      :src="
                        'https://tmd-dev.azurewebsites.net/TodayWeatherWidget?province=' +
                        city
                      "
                      height="350px"
                      scrolling="yes"
                      frameBorder="0"
                      align="center"
                    ></iframe>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="9"
                    lg="9"
                    md="12"
                    sm="12"
                    xs="12"
                    class="pt-2 pb-0"
                    align="center"
                  >
                    <iframe
                      id="7d"
                      :src="
                        'https://tmd-dev.azurewebsites.net/weatherForecast7DaysWidget?province=' +
                        city
                      "
                      height="350px"
                      width="100%"
                      scrolling="no"
                      frameBorder="0"
                    ></iframe>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" class="mt-6">
              <v-row
                ><v-col cols="12" xl="3" lg="3" md="4" sm="12" sx="12">
                  <v-card width="100%" min-height="416px">
                    <v-row>
                      <v-col cols="12" xl="4" lg="4" md="4" sm="12" sx="12">
                        <div style="height: 50%">
                          <vue-three-sixty
                            :amount="5"
                            :imagePath="checkBoxPump(getStation)"
                            fileName="{index}.png"
                            style="min-width: 250px"
                          />
                        </div>
                        <div style="height: 100%">
                          <vue-three-sixty
                            :amount="4"
                            :imagePath="`../../img/${getStation.type.name}`"
                            fileName="{index}.png"
                            style="min-width: 250px"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" xl="9" lg="9" md="8" sm="12" sx="12">
                  <v-row
                    style="overflow: auto; overflow-y: scroll; height: 240px"
                    :style="
                      getAccess.role == 'Engineer' ||
                      getAccess.role == 'Admin Office' ||
                      getAccess.role == 'Admin Numpol'
                        ? 'height:240px'
                        : 'height:400px'
                    "
                  >
                    <v-col cols="6" xl="2" lg="2" md="3">
                      <v-card
                        height="100px"
                        :id="
                          getStation.status[1].value < 15 &&
                          getStation.status[1].value > 11
                            ? 'elem'
                            : ''
                        "
                        :style="colorCard(getStation.status[1].value)"
                        :dark="getStation.status[1].value < 15"
                      >
                        <p
                          class="mb-0 pt-1"
                          style="font-size: small; text-align: center"
                        >
                          {{ $t("Fuel Level") }}
                        </p>
                        <p
                          class="my-0"
                          style="font-size: large; text-align: center"
                        >
                          {{ getStation.status[0].value.toFixed(0) }} Lite
                        </p>
                        <p
                          class="my-0"
                          style="font-size: large; text-align: center"
                        >
                          {{ getStation.status[1].value }} %
                        </p>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="6"
                      xl="2"
                      lg="2"
                      md="3"
                      class="pb-1"
                      v-for="item in getStation.status.filter(
                        (element) =>
                          element.name !== 'Fuel Level(%)' &&
                          element.name !== 'Fuel Level'
                      )"
                      :key="item._id"
                    >
                      <v-card height="100px">
                        <p
                          class="mb-0 pt-1"
                          style="font-size: small; text-align: center"
                        >
                          {{ $t(item.name) }}
                        </p>
                        <p
                          v-if="
                            item.name == 'Engine run time' ||
                            item.name == 'Current run time'
                          "
                          class="my-0"
                          style="font-size: large; text-align: center"
                        >
                          {{ splitTime(item.value) }}
                        </p>
                        <p
                          v-else-if="item.name == 'Current Flow'"
                          class="my-0"
                          style="font-size: large; text-align: center"
                        >
                          <v-row>
                            <v-col
                              style="font-size: large; text-align: center"
                              justify="center"
                              align="center"
                              class="pr-0"
                              >{{
                                nFormatter(
                                  changeUnitFlow(item.value, unitCurrentFlow),
                                  2
                                )
                              }}
                            </v-col>
                            <v-col justify="end" class="pl-0">
                              <v-select
                                class="select"
                                v-model="unitCurrentFlow"
                                v-if="item.name == 'Current Flow'"
                                :items="['Lite', 'm³']"
                                hide-details
                                dense
                              ></v-select
                            ></v-col>
                          </v-row>
                        </p>

                        <p
                          v-else-if="item.name == 'Flow Totalizer'"
                          style="text-align: center"
                        >
                          <v-row class="mt-2">
                            <v-col
                              style="font-size: large; text-align: center"
                              justify="center"
                              align="center"
                              class="pr-0"
                              >{{
                                nFormatter(
                                  changeUnitSumflow(item.value, unitSumFlow),
                                  2
                                )
                              }}
                            </v-col>

                            <v-col justify="end" class="pl-0">
                              <v-select
                                class="select"
                                v-model="unitSumFlow"
                                v-if="item.name == 'Flow Totalizer'"
                                :items="['Lite', 'm³']"
                                hide-details
                                dense
                              ></v-select
                            ></v-col>
                          </v-row>
                        </p>
                        <p v-else style="font-size: large; text-align: center">
                          {{ item.value.toLocaleString() }}
                          {{ unit[item.name] }}
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      getAccess.role == 'Engineer' ||
                      getAccess.role == 'Admin Office' ||
                      getAccess.role == 'Admin Numpol'
                    "
                  >
                    <v-col cols="12" xl="6" lg="6" md="6">
                      <v-card width="100%" min-height="165px">
                        <span class="ma-2 text-center">
                          {{ $t("Control Engine") }}
                        </span>

                        <v-col class="pt-0">
                          <v-row justify="center">
                            <v-col cols="12" justify="center" align="center">
                              <v-btn
                                @click="openpopupConfrim('set manual')"
                                :color="action == 'manual' ? 'info' : 'grey'"
                                dark
                                class="mr-3"
                                small
                              >
                                manual</v-btn
                              >
                            </v-col>
                            <v-col cols="6" align="end">
                              <v-btn
                                @click="openpopupConfrim('set start engine')"
                                :color="
                                  action == 'start' || getStation.engine == true
                                    ? 'success'
                                    : 'grey'
                                "
                                class="mr-3"
                                small
                                dark
                              >
                                start</v-btn
                              >
                            </v-col>
                            <v-col cols="6">
                              <v-btn
                                @click="openpopupConfrim('set stop engine')"
                                :color="
                                  action == 'stop' || getStation.engine == false
                                    ? 'error'
                                    : 'grey'
                                "
                                small
                                dark
                              >
                                <span>
                                  {{
                                    time == "00:00" ? "Stop" : "Cool down..."
                                  }}
                                </span>
                              </v-btn>
                            </v-col>
                            <v-col>
                              <span>
                                {{ $t("Time count of cool down") }}: {{ time }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                      <v-card min-height="165px">
                        <span class="pa-2 text-center">
                          {{ $t("Control Mode Pump") }}
                        </span>

                        <v-col justify="center" align="center">
                          <v-btn
                            @click="openpopupConfrim('set eco mode')"
                            :color="
                              action == 'onpump1' ||
                              getStation.pump == 'Eco Mode'
                                ? 'info'
                                : 'grey'
                            "
                            class="mr-3 mt-2"
                            small
                            dark
                          >
                            Eco Mode</v-btn
                          >
                          <v-btn
                            @click="openpopupConfrim('set normal mode')"
                            :color="
                              action == 'onpump2' ||
                              getStation.pump == 'Normal Mode'
                                ? 'info'
                                : 'grey'
                            "
                            class="mr-3 mt-2"
                            small
                            dark
                            >normal mode
                          </v-btn>
                          <v-btn
                            @click="openpopupConfrim('set sport mode')"
                            :color="
                              action == 'onpump3' ||
                              getStation.pump == 'Sport Mode'
                                ? 'info'
                                : 'grey'
                            "
                            class="mr-3 mt-2"
                            small
                            dark
                            >Sport mode
                          </v-btn>
                          <v-btn
                            @click="openpopupConfrim('set off pump')"
                            :color="
                              action == 'off' || getStation.pump == 'Off Pump'
                                ? 'info'
                                : 'grey'
                            "
                            class="mr-3 mt-2"
                            small
                            dark
                            >off pump
                          </v-btn>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <p style="font-size: 14" class="mb-0">
                    <span>
                      <v-icon color="info"> mdi-map-marker </v-icon>
                      {{ getStation.GPS.lat.toFixed(4) }}
                      {{ getStation.GPS.lng.toFixed(4) }}
                      <v-btn
                        x-small
                        rounded
                        color="info"
                        @click="showMap = true"
                      >
                        {{ $t("show map") }}</v-btn
                      >
                    </span>

                    <br />
                    {{ $t("Pump Model") }}:
                    {{ $t(`modelPump.${getStation.type.name}`) }}
                    <br />
                    {{ $t("Pump Serial No") }}: {{ getStation.serial }}
                    <br />
                    {{ $t("Engine Serial") }}:
                    {{ getStation.engineSerial }}
                    <br />
                    {{ $t("Hydraulic Pump Serial No") }} :
                    {{ getStation.hydraulicPumpSerial }}
                    <br />
                    {{ $t("Hydraulic Motor Serial No") }} :
                    {{ getStation.hydraulicMotorSerial }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col> </v-tab-item
          ><v-tab-item>
            <v-container fluid>
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <div>
                    <date-picker
                      range
                      v-model="searchDataLogs.dateLength"
                      type="date"
                    ></date-picker>
                    <v-btn color="info" small text @click="searchLog()">{{
                      $t("Search")
                    }}</v-btn>
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="info" small v-bind="attrs" v-on="on">
                          {{ $t("Download") }}
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-list>
                          <v-list-item>
                            <v-list-item-content
                              v-if="getAccess.role != 'Technician'"
                            >
                              <v-btn color="info" small>
                                <download-excel
                                  :data="getLogs"
                                  :fields="rowLogs"
                                  :name="
                                    new Date().toISOString().substring(0, 10) +
                                    '_' +
                                    getStation.name +
                                    '_logs.csv'
                                  "
                                  type="csv"
                                >
                                  Excel
                                </download-excel>
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                color="info"
                                small
                                @click="generateReport('logs')"
                              >
                                PDF
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </v-card-title>
                <DataTable
                  :items="getLogs"
                  :headers="headersLogs"
                  :loading="loadingLog"
                  :itemperpage="5"
                >
                </DataTable>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid>
              <v-card>
                <v-tabs v-model="tabAlarm">
                  <v-tab>{{ $t("Active Alarms") }} </v-tab>
                  <v-tab>{{ $t("History of Alarms") }}</v-tab
                  ><v-tabs-items v-model="tabAlarm">
                    <v-tab-item>
                      <DataTable
                        :items="getAlarmActiveStion"
                        :headers="headersActive"
                        :loading="loadingAlarm"
                        :itemperpage="5"
                      ></DataTable>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <div>
                          <date-picker
                            range
                            v-model="searchDataAlarm.dateLength"
                            type="date"
                          ></date-picker>
                          <v-btn
                            color="info"
                            small
                            text
                            @click="searchAlarm()"
                            >{{ $t("Search") }}</v-btn
                          >
                          <v-menu :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ $t("Download") }}
                                <v-icon right dark> mdi-download </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-list>
                                <v-list-item>
                                  <v-list-item-content
                                    v-if="getAccess.role != 'Technician'"
                                  >
                                    <v-btn color="info" small>
                                      <download-excel
                                        :data="getAlarmStation"
                                        :fields="rowAlarm"
                                        :name="
                                          new Date()
                                            .toISOString()
                                            .substring(0, 10) +
                                          '_' +
                                          getStation.name +
                                          '_data_alarm.csv'
                                        "
                                        type="csv"
                                      >
                                        Excel
                                      </download-excel>
                                    </v-btn>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-btn
                                      color="info"
                                      small
                                      @click="generateReport('alarm')"
                                    >
                                      PDF
                                    </v-btn>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </div>
                      </v-card-title>
                      <DataTable
                        :items="getAlarmStation"
                        :headers="headersAlarm"
                        :loading="loadingAlarm"
                        :itemperpage="5"
                      >
                      </DataTable>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid>
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <div>
                    <date-picker
                      range
                      v-model="searchDataTracking.dateLength"
                      type="date"
                    ></date-picker>
                    <v-btn color="info" text small @click="searchTracking()">{{
                      $t("Search")
                    }}</v-btn>
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="info" small v-bind="attrs" v-on="on">
                          {{ $t("Download") }}
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-list>
                          <v-list-item>
                            <v-list-item-content
                              v-if="getAccess.role != 'Technician'"
                            >
                              <v-btn color="info" small @click="onExport">
                                Excel
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-btn
                                color="info"
                                small
                                @click="generateReport('tracking')"
                              >
                                PDF
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </v-card-title>
                <DataTableExpan
                  :headers="headersTracking"
                  :items="getTracking"
                  :items-per-page="5"
                  :loading="loadingTracking"
                >
                </DataTableExpan>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid>
              <v-card class="mx-auto">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <div>
                    <date-picker
                      range
                      v-model="searchData.dateLength"
                      type="date"
                    ></date-picker>
                    <v-btn small color="info" text @click="search()">{{
                      $t("Search")
                    }}</v-btn>
                  </div>
                </v-card-title>
                <v-row>
                  <v-col cols="12" xl="6" lg="6">
                    <v-card-title>
                      <h4>{{ $t("Volume Of Water") }}</h4>
                    </v-card-title>

                    <div class="justify-center text-center">
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="teal"
                        style="position: relative; top: 20vh; z-index: 2"
                      ></v-progress-circular>
                    </div>

                    <apexChart
                      width="500"
                      :options="getChartsFlow.chartOptions"
                      :series="getChartsFlow.series"
                      :loading="loading"
                    ></apexChart>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6">
                    <v-card-title>
                      <h4>{{ $t("Total Fuel") }}</h4>
                    </v-card-title>

                    <div class="justify-center text-center">
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="teal"
                        style="position: relative; top: 20vh; z-index: 2"
                      ></v-progress-circular>
                    </div>
                    <apexChart
                      width="500"
                      type="line"
                      :options="getChartsOil.chartOptions"
                      :series="getChartsOil.series"
                      :loading="loading"
                    ></apexChart>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="
        new Date().toISOString().substring(0, 10) +
        '_' +
        getStation.name +
        '_data_alarms'
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      @progress="onProgress($event)"
      @hasGenerated="hasGenerated($event)"
      ref="alarms"
    >
      <section slot="pdf-content">
        <DataTable
          :items="getAlarmStation"
          :headers="headersAlarm"
          :loading="loadingAlarm"
          :itemperpage="-1"
        >
        </DataTable>
      </section>
    </vue-html2pdf>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="
        new Date().toISOString().substring(0, 10) +
        '_' +
        getStation.name +
        '_data_logs'
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      @progress="onProgress($event)"
      @hasGenerated="hasGenerated($event)"
      ref="logs"
    >
      <section slot="pdf-content">
        <DataTable
          :items="getLogs"
          :headers="headersLogs"
          :loading="loadingLog"
          :itemperpage="-1"
        >
        </DataTable>
      </section>
    </vue-html2pdf>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="
        new Date().toISOString().substring(0, 10) +
        '_' +
        getStation.name +
        '_data_trackings'
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      @progress="onProgress($event)"
      @hasGenerated="hasGenerated($event)"
      ref="trackings"
    >
      <section slot="pdf-content">
        <DataTableExpanPDF
          :headers="headersTracking"
          :items="getTracking"
          :items-per-page="-1"
          :loading="loadingTracking"
        >
        </DataTableExpanPDF>
      </section>
    </vue-html2pdf>
    <v-dialog v-model="showMap" max-width="600" persistent>
      <v-card>
        <v-card-title dark class="h5 dark info white--text">
          {{ $t("Map") }}
          <v-spacer></v-spacer>
          <v-btn @click="openAppMap">
            {{ $t("Open in Google Map") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <CardMap
            class="mt-6"
            :stations="[getStation]"
            :bngLat="getStation.GPS.lat"
            :bngLng="getStation.GPS.lng"
            :height="400"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            text
            color="teal accent-4"
            @click="showMap = false"
            class="text-center"
          >
            {{ $t("Close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="popupConfrim" persistent max-width="500">
      <v-card>
        <v-card-title class="h5">
          {{ $t("Please Confirm") }}
        </v-card-title>
        <v-card-text>
          {{ $t("Are you sure you want to ") }} {{ $t(this.typeControl) }}
          <br />
          <div v-if="this.typeControl == 'set manual'">
            กรุณาทำการตรวจสอบความพร้อมก่อนเริ่มทำงาน
            <v-checkbox
              v-model="selected"
              label="ระดับน้ำมันควรมีปริมาณไม่น้อยกว่า 15% (ถังน้ำมันบรรจุ 400 ลิตร)"
              value="1"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="ตรวจเช็กน้ำมันเครื่อง"
              value="2"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="ระดับน้ำหล่อเย็นควรเติมให้เต็มที่ MAX เสมอเวลาจะใช้งาน"
              value="3"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="ระดับน้ำมันไฮดรอลิคควรมีไม่น้อยกว่า 80% เพื่อมีน้ำมันหมุนเวียนเพียงพอในระบบ"
              value="4"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="เช็คสายข้อต่อ(Low Pressure) ที่ต่อออกจากตัวปั๊มน้ำมันไฮดรอลิค(High Pressure) ต่อกลับเข้าปั๊มน้ำมันไฮดรอลิค และสาย Drain น้ำมันสำหรับการ Refuel น้ำมัน (Line Dain) "
              value="5"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="เช็กสาบข้อต่อสวมเร็ว ที่ต่อเข้ากับชุดหัวสูบและชุดต้นกำลัง"
              value="6"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="ตรวจสอบทิศทางการหมุนของใบพัดก่อนการติดตั้งใช้งาน"
              value="7"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="ไฟแบตเตอรี่ควรมีไม่ต่ำกว่า 24-28 V"
              value="8"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="เช็คชุดสะพานไฟและความพร้อมของรีเลย์"
              value="9"
            ></v-checkbox>
            <v-checkbox
              v-model="selected"
              label="ไม่มีคนกำลังซ่อมบำรุงเครื่องสูบน้ำหรือได้รับอันตราย"
              value="10"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="popupConfrim = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="controlPump">
            {{ $t("Confrim") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notifications />
    <v-overlay style="z-index: 10" v-if="loadingPdf < 100">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="loadingPdf"
        color="primary"
      >
        {{ loadingPdf }} %
      </v-progress-circular>
    </v-overlay>
    <DataTableExpanExcel
      style="display: none"
      id="tbl_exporttable_to_xls"
      :items="getTracking"
      :headers="headersTracking"
    />
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import DataTableExpan from "@/components/DataTableExpan.vue";
import DataTableExpanPDF from "@/components/DataTableExpanPDF.vue";
import DataTableExpanExcel from "@/components/DataTableExpanExcel.vue";
import XLSX from "xlsx-js-style";
import VueHtml2pdf from "vue-html2pdf";
import CardMap from "@/components/Overview/CardMap.vue";
import unit from "../unit.json";
import { convert } from "geo-coordinates-parser";
export default {
  name: "StationPage",
  components: {
    CardMap,
    VueHtml2pdf,
    DataTable,
    DataTableExpan,
    DataTableExpanPDF,
    DataTableExpanExcel,
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getStation() {
      return this.$store.getters.oneStation;
    },
    getLogs() {
      return this.$store.getters.getLogs;
    },
    getChartsFlow() {
      console.log(this.$store.getters.getChartFlow);
      return this.$store.getters.getChartFlow;
    },
    getChartsOil() {
      console.log(this.$store.getters.getChartOil);
      return this.$store.getters.getChartOil;
    },
    getAlarmStation() {
      return this.$store.getters.getAlarmStation;
    },
    getAlarmActiveStion() {
      return this.$store.getters.getAlarmActiveStion;
    },
    getTracking() {
      return this.$store.getters.getTracking;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/");
    }
  },
  mounted() {
    //console.log(this.$route.params.id);
    //this.loadCharts();
    this.$store.dispatch("getStation", this.$route.params.id).then((res) => {
      this.loadingStation = false;
      //console.log(res);
      if (res.data.GPS) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.data.GPS.lat},${res.data.GPS.lng}&key=${process.env.VUE_APP_MAPS_API_KEY}`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            let getcity = data.results[0].address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            ).short_name;
            getcity = getcity.split("จ.");
            this.city = getcity[1];
            console.log(this.city);
          })
          .catch((error) => console.log(error));
      }
      if (res.data._id) {
        let now = [
          new Date().toISOString().substring(0, 10),
          new Date().toISOString().substring(0, 10),
        ];
        this.searchDataAlarm.dateLength = now;
        this.searchDataTracking.dateLength = now;
        this.searchDataLogs.dateLength = now;
        this.searchLog();
        this.searchAlarm();
        this.searchTracking();
      }
    });
    this.sockets.subscribe("time", (data) => {
      const n = data.find((d) => d.station == this.getStation._id);
      if (n) {
        this.time = n.time;
      }
    });
  },
  data() {
    return {
      selected: [],
      loadingPdf: 100,
      loadingStation: true,
      showMap: false,
      expanded: [],
      popupConfrim: false,
      rowLogs: {
        Date: {
          field: "datetime",
          callback: (value) => {
            return new Date(value).toLocaleDateString("th-TH");
          },
        },
        Time: {
          field: "datetime",
          callback: (value) => {
            return new Date(value).toLocaleTimeString("th-TH");
          },
        },
        Description: "description",
        Accounts: "user.name",
      },

      rowAlarm: {
        "Date Start": {
          field: "datetimeStart",
          callback: (value) => {
            return new Date(value).toLocaleDateString("th-TH");
          },
        },
        "Time Start": {
          field: "datetimeStart",
          callback: (value) => {
            return new Date(value).toLocaleTimeString("th-TH");
          },
        },
        "Date Stop": {
          field: "datetimeStop",
          callback: (value) => {
            return new Date(value).toLocaleDateString("th-TH");
          },
        },
        "Time Stop": {
          field: "datetimeStop",
          callback: (value) => {
            return new Date(value).toLocaleTimeString("th-TH");
          },
        },
        Alarm: {
          field: "alarm",
          callback: (value) => {
            for (let i = 0; i < value.length; i++) {
              return value[i].datetimeStart;
            }
          },
        },
        "Total Time": {
          field: "totaltime",
          callback: (value) => {
            return this.splitTime(value);
          },
        },
      },
      rowTracking: {
        "Date Start": {
          field: "datetimeStart",
          callback: (value) => {
            return new Date(value).toLocaleDateString("th-TH");
          },
        },
        "Time Start": {
          field: "datetimeStart",
          callback: (value) => {
            return new Date(value).toLocaleTimeString("th-TH");
          },
        },
        "Date Stop": {
          field: "datetimeStop",
          callback: (value) => {
            return new Date(value).toLocaleDateString("th-TH");
          },
        },
        "Time Stop": {
          field: "datetimeStop",
          callback: (value) => {
            return new Date(value).toLocaleTimeString("th-TH");
          },
        },
        "Volume Of Water": "flowTotalrizer.toLocaleString()",
        "Total Fuel": "totalFuel.toLocaleString()",
        Alarm: "alarm",
      },
      headersTracking: [
        { text: "Date Start", value: "dateStart" },
        { text: "Time Start", value: "timeStart" },
        { text: "Date Stop", value: "dateStop" },
        { text: "Time Stop", value: "timeStop" },
        { text: "Volume Of Water (Lite)", value: "flowTotalrizer" },
        { text: "Total Fuel (Lite)", value: "totalFuel" },
        { text: "Total Time", value: "totaltime" },
        { text: "Alarm", value: "data-table-expand" },
      ],
      headersActive: [
        { text: "Date Start", value: "dateStart" },
        { text: "Time Start", value: "timeStart" },
        { text: "Alarm", value: "alarm" },
      ],
      headersAlarm: [
        { text: "Date Start", value: "dateStart" },
        { text: "Time Start", value: "timeStart" },
        { text: "Date Stop", value: "dateStop" },
        { text: "Time Stop", value: "timeStop" },
        { text: "Alarm", value: "alarm" },
        { text: "Total Time", value: "totaltime" },
      ],
      headersLogs: [
        { text: "Date", value: "date" },
        { text: "Time", value: "time" },
        { text: "Description", value: "description" },
        { text: "Accounts", value: "user" },
      ],
      loadingTracking: false,
      loadingAlarm: false,
      loadingLog: false,
      loading: false,
      searchData: {
        station: "",
        dateLength: "",
      },
      searchDataAlarm: {
        station: "",
        dateLength: "",
      },
      searchDataTracking: {
        station: "",
        dateLength: "",
      },
      searchDataLogs: {
        station: "",
        dateLength: "",
      },
      action: "",
      time: "00:00",
      city: null,
      unit: unit,
      tab: null,
      items: [
        {
          name: "Status Pump",
          icon: "mdi-pump",
        },
        { name: "Activity Control", icon: "mdi-tune-vertical" },
        { name: "Alarms", icon: "mdi-bell" },
        { name: "Tracking", icon: "mdi-calendar-blank" },
        { name: "Chart", icon: "mdi-chart-bar" },
      ],
      typeControl: "",
      unitSumFlow: "Lite",
      unitCurrentFlow: "Lite",
      tabAlarm: null,
    };
  },
  methods: {
    checkBoxPump(getStation) {
      if (getStation.office.name == "กรมชลประทาน") {
        return `../../img/box/rid/${getStation.type.name}`;
      } else if (getStation.office.name == "กรมทรัพยากรน้ำ") {
        return `../../img/box/dwr/${getStation.type.name}`;
      } else {
        return `../../img/box/ddpm/${getStation.type.name}`;
      }
    },
    onExport() {
      const elt = document.getElementById("tbl_exporttable_to_xls");
      const wb = XLSX.utils.table_to_book(elt);

      XLSX.writeFile(
        wb,

        new Date().toISOString().substring(0, 10) +
          "_" +
          this.getStation.name +
          "_tracking" +
          "." +
          "xlsx"
      );
    },
    onProgress(event) {
      console.log(event);
      this.loadingPdf = event;
    },
    colorCard(data) {
      if (data < 15 && data > 11) {
        //console.log(data);
        return "background-color: orange;";
      } else if (data < 11) {
        return "background-color: red";
      }
    },
    generateReport(data) {
      this.loadingPdf = 10;
      switch (data) {
        case "logs":
          this.$refs.logs.generatePdf();
          break;
        case "alarm":
          this.$refs.alarms.generatePdf();
          break;
        case "tracking":
          this.$refs.trackings.generatePdf();
          break;
      }
    },
    changeUnitSumflow(data, unit) {
      if (unit == "Lite") {
        return data;
      } else {
        return data / 1000;
      }
    },
    changeUnitFlow(data, unit) {
      if (unit == "Lite") {
        return data;
      } else {
        return data / 1000;
      }
    },
    nFormatter(num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
      const item = lookup.findLast((item) => num >= item.value);
      return item
        ? (num / item.value)
            .toFixed(digits)
            .replace(regexp, "")
            .concat(item.symbol)
        : "0";
    },
    openAppMap() {
      let converted;
      converted = convert(
        `${this.getStation.GPS.lat}, ${this.getStation.GPS.lng}`
      );

      window.open(
        `https://www.google.com/maps/place/${converted.toCoordinateFormat(
          convert.to.DMS
        )}/@${this.getStation.GPS.lat}, ${this.getStation.GPS.lng}`
      );
    },
    splitTime(value) {
      let t = value.toString().split(".");

      if (t[1] == undefined) {
        t[1] = 0;
      }
      return parseInt(t[0]) + "h" + parseInt(t[1]) + "min";
      //console.log(t);
    },
    callSetManual() {
      this.action = "manual";
      this.$store
        .dispatch("setManual", this.getStation.gatewaySerial)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Success, Control Manual Success",
          });
          this.popupConfrim = false;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
    },
    callSetStart() {
      this.action = "start";
      this.$store
        .dispatch("setStart", this.getStation.gatewaySerial)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Success, Control Start Success",
          });
          this.popupConfrim = false;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
    },
    callSetOnpump1() {
      this.action = "onpump1";
      this.$store
        .dispatch("setOnPump1", this.getStation.gatewaySerial)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Success, On Pump Step 1 Success",
          });
          this.popupConfrim = false;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
    },
    callSetOnpump2() {
      this.action = "onpump2";
      this.$store
        .dispatch("setOnPump2", this.getStation.gatewaySerial)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Success, On Pump Step 2 Success",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
      this.popupConfrim = false;
    },
    callSetOnpump3() {
      this.action = "onpump3";
      this.$store
        .dispatch("setOnPump3", this.getStation.gatewaySerial)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Success, On Pump Step 3 Success",
          });
          this.popupConfrim = false;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
    },
    callSetOffpump() {
      this.action = "off";
      this.$store
        .dispatch("setOffPump", this.getStation.gatewaySerial)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Success, Off Pump Success",
          });
          this.popupConfrim = false;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
    },
    callSetStop() {
      this.action = "stop";
      this.$store
        .dispatch("setStop", this.getStation.gatewaySerial)
        .then(() => {
          //console.log(res);
          this.$notify({
            type: "success",
            text: "Success, Stop pump Success",
          });
          this.popupConfrim = false;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        });
    },
    searchAlarm() {
      this.loadingAlarm = true;
      console.log(this.searchDataAlarm.dateLength);
      let payload = {
        station: this.getStation._id,
        datetimeStart: this.reformatDate(this.searchDataAlarm.dateLength[0]),
        datetimeStop: this.reformatDate(this.searchDataAlarm.dateLength[1]),
      };
      this.$store.dispatch("searchAciveAlarm", this.getStation._id);
      this.$store.dispatch("searchAlarm", payload).then(
        () => {
          this.loadingAlarm = false;
          this.$notify({
            type: "success",
            text: "Success, Searching alarms complete",
          });
        },
        (error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        }
      );
    },
    searchTracking() {
      this.loadingTracking = true;
      let payload = {
        station: this.getStation._id,
        datetimeStart: this.reformatDate(this.searchDataTracking.dateLength[0]),
        datetimeStop: this.reformatDate(this.searchDataTracking.dateLength[1]),
      };
      this.$store.dispatch("searchTracking", payload).then(
        () => {
          this.loadingTracking = false;
          this.$notify({
            type: "success",
            text: "Success, Searching tracking complete",
          });
        },
        (error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        }
      );
    },
    searchLog() {
      this.loadingLog = true;
      let payload = {
        station: this.getStation._id,
        datetimeStart: this.reformatDate(this.searchDataLogs.dateLength[0]),
        datetimeStop: this.reformatDate(this.searchDataLogs.dateLength[1]),
      };
      this.$store.dispatch("searchLog", payload).then(
        () => {
          this.loadingLog = false;
          this.$notify({
            type: "success",
            text: "Success, Searching events complete",
          });
        },
        (error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        }
      );
    },
    search() {
      this.loading = true;
      let payload = {
        station: this.getStation._id,
        startDate: this.reformatDate(this.searchData.dateLength[0]),
        endDate: this.reformatDate(this.searchData.dateLength[1]),
      };
      this.$store.dispatch("searchCharts", payload).then(
        (res) => {
          console.log(res);
          this.loading = false;
          this.$notify({
            type: "success",
            text: "Success, Searching Complete",
          });
        },
        (error) => {
          this.$notify({
            type: "error",
            text: "Error, " + error,
          });
        }
      );
    },
    reformatDate(date) {
      let d = new Date(date);
      let dd = d.getDate();
      let mm = d.getMonth() + 1;
      let yyyy = d.getFullYear();
      let dataDate = yyyy + "-" + this.addZero(mm) + "-" + this.addZero(dd);

      return dataDate;
    },
    addZero(number) {
      return ("0" + number).slice(-2);
    },
    controlPump() {
      switch (this.typeControl) {
        case "set start engine":
          this.callSetStart();
          break;
        case "set manual":
          if (this.selected.length == 10) {
            this.callSetManual();
          } else {
            this.$notify({
              type: "error",
              text: "กรุณาทำการตรวจสอบหน้างานเพื่อความปลอดภัย",
            });
          }

          break;
        case "set off pump":
          this.callSetOffpump();
          break;
        case "set eco mode":
          this.callSetOnpump1();
          break;
        case "set normal mode":
          this.callSetOnpump2();
          break;
        case "set sport mode":
          this.callSetOnpump3();
          break;
        case "set stop engine":
          this.callSetStop();
          break;
      }
    },
    openpopupConfrim(data) {
      this.typeControl = data;
      this.popupConfrim = true;
    },
  },
};
</script>

<style lang="css" scoped>
.select {
  max-width: 50px;
  max-height: 50px;
  font-size: 10px;
}
#elem {
  animation: blink 0.8s infinite;
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
