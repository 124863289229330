<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <h1>Reset Password</h1>
                </div>
                <v-form @keyup.native.enter="change">
                  <v-container fluid>
                    <v-text-field
                      :type="hideNewPassword ? 'password' : 'text'"
                      :append-icon="
                        hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                      "
                      name="newPassword"
                      label="New Password"
                      id="newPassword"
                      :rules="[(v) => !!v || 'New Password is required']"
                      v-model="password.new"
                      :error="error"
                      @click:append="hideNewPassword = !hideNewPassword"
                      @keypress="isLetter($event)"
                      color="info"
                    />
                    <v-text-field
                      :type="hideConfirmPassword ? 'password' : 'text'"
                      :append-icon="
                        hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                      "
                      name="confirmPassword"
                      label="Confirm Password"
                      id="confirmPassword"
                      :rules="[(v) => !!v || 'Confirm Password is required']"
                      v-model="password.confirm"
                      :error="error"
                      @click:append="hideConfirmPassword = !hideConfirmPassword"
                      @keypress="isLetter($event)"
                      color="info"
                    />
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="white--text" block color="info" @click="change"
                  >Reset</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" timeout="1500" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      password: {
        new: "",
        confirm: "",
      },
      payload: {
        id: "",
        newPassword: "",
      },
      loading: false,
      hideNewPassword: true,
      hideConfirmPassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.$store.dispatch("auth/decode");
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  created() {
    if (this.getAccess == null) {
      this.$router.push("/");
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    change() {
      if (this.password.new == this.password.confirm) {
        this.payload.id = this.getAccess._id;
        this.payload.newPassword = this.password.new;
        if (
          /^[A-Za-z0-9]+$/.test(this.password.new) &&
          /^[A-Za-z0-9]+$/.test(this.password.confirm)
        ) {
          this.$store.dispatch("auth/resetPassword", this.payload).then(
            () => {
              this.$router.push("/overview");
            },
            (error) => {
              this.loading = false;
              this.error = true;
              this.result = "";
              this.showResult = true;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        } else {
          this.result = "Error! Password can only use A-Z a-z 0-9";
          this.showResult = true;
        }
      } else {
        this.result = "New Password and Confirm Password do not match";
        this.showResult = true;
        return;
      }
    },
  },
};
</script>
