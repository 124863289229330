<template>
  <div>
    <table id="tbl_exporttable_to_xls" style="width: 100%">
      <thead>
        <tr>
          <th
            v-for="h in headers"
            :key="h"
            style="width: 12.5%; text-align: center; font-weight: bolder"
            align="center"
          >
            <div>{{ h.text }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="r in items" :key="r">
          <td align="center">
            {{ new Date(r.datetimeStart).toLocaleDateString($t("dateTime")) }}
          </td>
          <td align="center">
            {{ new Date(r.datetimeStart).toLocaleTimeString($t("dateTime")) }}
          </td>
          <td align="center" v-if="r.totaltime != undefined">
            {{ new Date(r.datetimeStop).toLocaleDateString($t("dateTime")) }}
          </td>
          <td v-else></td>
          <td align="center" v-if="r.totaltime != undefined">
            {{ new Date(r.datetimeStop).toLocaleTimeString($t("dateTime")) }}
          </td>
          <td v-else></td>
          <td align="center">
            {{ r.flowTotalrizer.toLocaleString() }}
          </td>
          <td align="center">
            {{ r.totalFuel.toLocaleString() }}
          </td>
          <td align="center" v-if="r.totaltime != undefined">
            {{ splitTime(r.totaltime) }}
          </td>
          <td v-else></td>
          <td>
            {{ r.alarms.length }}
            <table v-if="r.alarms.length > 0">
              <tr>
                <th
                  v-for="h in headersAlarm"
                  :key="h"
                  style="width: 12.5%; text-align: center; font-weight: bolder"
                  align="center"
                >
                  <div>{{ h.text }}</div>
                </th>
              </tr>

              <tr v-for="a in r.alarms" :key="a">
                <td></td>
                <td>
                  {{
                    new Date(a.datetimeStart).toLocaleDateString($t("dateTime"))
                  }}
                </td>
                <td>
                  {{
                    new Date(a.datetimeStart).toLocaleTimeString($t("dateTime"))
                  }}
                </td>
                <td>
                  {{
                    new Date(a.datetimeStop).toLocaleDateString($t("dateTime"))
                  }}
                </td>
                <td>
                  {{
                    new Date(a.datetimeStop).toLocaleTimeString($t("dateTime"))
                  }}
                </td>
                <td>
                  {{ a.alarm }}
                </td>
                <td>
                  {{ splitTime(a.totaltime) }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["items", "headers"],
  computed: {},
  data() {
    return {
      headersAlarm: [
        { text: "Alarm", value: "" },
        { text: "Date Start", value: "dateStart" },
        { text: "Time Start", value: "timeStart" },
        { text: "Date Stop", value: "dateStop" },
        { text: "Time Stop", value: "timeStop" },
        { text: "Alarm", value: "alarm" },
        { text: "Total Time", value: "totaltime" },
      ],
    };
  },
  methods: {
    splitTime(value) {
      let t = value.toString().split(".");

      if (t[1] == undefined) {
        t[1] = 0;
      }
      return parseInt(t[0]) + "h" + parseInt(t[1]) + "min";
      //console.log(t);
    },
  },
};
</script>

<style lang="sass" scoped></style>
