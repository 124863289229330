<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm5 md4 lg4 xl4
            ><div class="justify-center"></div>
            <v-card class="elevation-1 pa-3" width="100%">
              <v-row class="layout align-center justify-center my-2">
                <img :src="require('/public/img/logo.png')" width="60%" />
              </v-row>
              <v-card-text>
                <v-form @keyup.native.enter="login">
                  <v-text-field
                    append-icon="mdi-account"
                    name="username"
                    label="Username"
                    type="text"
                    v-model="user.username"
                    :rules="[rules.required]"
                    color="#000"
                    @keypress="isLetter($event)"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                    "
                    name="password"
                    label="Password"
                    id="password"
                    :rules="[rules.required]"
                    v-model="user.password"
                    @click:append="hidePassword = !hidePassword"
                    @keypress="isLetter($event)"
                    color="#000"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="white--text" block color="info" @click="login"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" timeout="1500" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      showResult: false,
      result: "",
      hidePassword: true,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/overview");
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    login() {
      if (!this.user.username || !this.user.password) {
        this.result = "Username and Password can't be null.";
        this.showResult = true;
        return;
      } else {
        this.$store.dispatch("auth/login", this.user).then(
          (res) => {
            console.log(res);
            this.$store.dispatch("auth/decode").then(() => {
              if (this.getAccess.firstLogin) {
                this.$router.push("/resetPassword");
              } else {
                this.$router.push("/overview");
              }
            });
          },
          (error) => {
            console.log(error);
            this.message = "Incorrect username or password";
            this.loading = false;
            this.error = true;
            this.result = this.message;
            this.showResult = true;
          }
        );
      }
    },
  },
};
</script>
<style></style>
