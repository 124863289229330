<template>
  <div>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <h2>{{ $t("Suggestion") }}</h2>
        </v-col>
        <v-col cols="12">
          <v-textarea
            name="input-7-1"
            filled
            :label="$t('Suggestion')"
            auto-grow
            v-model="text"
          ></v-textarea>
          <v-btn color="info">
            {{ $t("Save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SuggestionPage",
  data() {
    return {
      text: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
