<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:[`header.dateStart`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.timeStart`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.dateStop`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.timeStop`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.flowTotalrizer`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.totalFuel`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.totaltime`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`header.data-table-expand`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`item.dateStart`]="{ item }">
        <td>
          {{ new Date(item.datetimeStart).toLocaleDateString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.timeStart`]="{ item }">
        <td>
          {{ new Date(item.datetimeStart).toLocaleTimeString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.dateStop`]="{ item }">
        <td v-if="item.totaltime != undefined">
          {{ new Date(item.datetimeStop).toLocaleDateString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.timeStop`]="{ item }">
        <td v-if="item.totaltime != undefined">
          {{ new Date(item.datetimeStop).toLocaleTimeString($t("dateTime")) }}
        </td>
      </template>
      <template v-slot:[`item.flowTotalrizer`]="{ item }">
        <td>
          {{ item.flowTotalrizer.toLocaleString() }}
        </td>
      </template>
      <template v-slot:[`item.totalFuel`]="{ item }">
        <td>
          {{ item.totalFuel.toLocaleString() }}
        </td>
      </template>
      <template v-slot:[`item.totaltime`]="{ item }">
        <td v-if="item.totaltime != undefined">
          {{ splitTime(item.totaltime) }}
        </td>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>
          <p v-for="(alarm, index) in item.alarms" :key="index">
            <b v-if="index == 0">
              {{ $t(headersAlarm[0].text) }}
            </b>
            <br />
            {{
              new Date(alarm.datetimeStart).toLocaleDateString($t("dateTime"))
            }}
          </p>
        </td>
        <td>
          <p v-for="(alarm, index) in item.alarms" :key="index">
            <b v-if="index == 0">
              {{ $t(headersAlarm[1].text) }}
            </b>
            <br />
            {{
              new Date(alarm.datetimeStart).toLocaleTimeString($t("dateTime"))
            }}
          </p>
        </td>
        <td>
          <p v-for="(alarm, index) in item.alarms" :key="index">
            <span>
              <b v-if="index == 0">
                {{ $t(headersAlarm[2].text) }}
              </b>
            </span>
            <br />
            {{
              new Date(alarm.datetimeStop).toLocaleDateString($t("dateTime"))
            }}
          </p>
        </td>
        <td>
          <p v-for="(alarm, index) in item.alarms" :key="index">
            <span>
              <b v-if="index == 0">
                {{ $t(headersAlarm[3].text) }}
              </b>
            </span>
            <br />
            {{
              new Date(alarm.datetimeStop).toLocaleTimeString($t("dateTime"))
            }}
          </p>
        </td>
        <td>
          <p v-for="(alarm, index) in item.alarms" :key="index">
            <span
              ><b v-if="index == 0">
                {{ $t(headersAlarm[4].text) }}
              </b>
            </span>
            <br />

            {{ alarm.alarm }}
          </p>
        </td>
        <td>
          <p v-for="(alarm, index) in item.alarms" :key="index">
            <span>
              <b v-if="index == 0">
                {{ $t(headersAlarm[5].text) }}
              </b>
              <br />
            </span>
            {{ splitTime(alarm.totaltime) }}
          </p>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["items", "headers", "loading", "itemperpage"],
  data() {
    return {
      expanded: [],
      headersAlarm: [
        { text: "Date Start", value: "dateStart" },
        { text: "Time Start", value: "timeStart" },
        { text: "Date Stop", value: "dateStop" },
        { text: "Time Stop", value: "timeStop" },
        { text: "Alarm", value: "alarm" },
        { text: "Total Time", value: "totaltime" },
      ],
    };
  },
  methods: {
    splitTime(value) {
      let t = value.toString().split(".");

      if (t[1] == undefined) {
        t[1] = 0;
      }
      return parseInt(t[0]) + "h" + parseInt(t[1]) + "min";
      //console.log(t);
    },
  },
};
</script>

<style lang="sass" scoped></style>
