<template>
  <div v-resize="onResize">
    <v-navigation-drawer
      v-if="!isMobile"
      dense
      app
      :mini-variant.sync="drawer"
      permanent
      width="220"
      floating
      color="#F8F8FA"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar v-if="!drawer">
          <v-img :src="checkOffice(this.getAccess)"></v-img>
        </v-list-item-avatar>

        <v-list-item-title v-if="!drawer">NIT PUMPS</v-list-item-title>
        <v-list-item-title v-else>
          <v-img :src="checkOffice(this.getAccess)"></v-img
        ></v-list-item-title>
        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-list nav>
        <div v-for="(item, i) in items" :key="i">
          <v-list-item
            v-if="!item.subLinks"
            :key="i"
            :to="item.link"
            :active-class="color"
            avatar
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :key="i" no-action :prepend-icon="item.icon">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="sublink in item.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              link
            >
              <v-list-tile-title>{{ $t(sublink.text) }}</v-list-tile-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#F8F8FA"
      dense
      flat
    >
      <v-spacer></v-spacer>
      <!-- <v-btn @click="changePageHome()" icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn> -->

      <v-menu rounded>
        <template v-slot:activator="{ attrs, on }">
          <div v-bind="attrs" v-on="on">
            <v-row align="center" style="width: 80pt">
              <v-col cols="6">
                <v-avatar size="30">
                  <img :src="flags[lang].img" />
                </v-avatar>
              </v-col>
              <v-col cols="6" class="px-0">
                {{ flags[lang].text }}
              </v-col>
            </v-row>
          </div>
        </template>
        <v-list dense flat>
          <v-list-item
            v-for="(flag, i) in flags"
            :key="i"
            link
            @click="changeLang(i)"
          >
            <v-list-item-title>
              <v-row align="center">
                <v-col cols="6">
                  <v-avatar size="30">
                    <img :src="flag.img" />
                  </v-avatar>
                </v-col>
                <v-col cols="6" class="pl-0 text--white">
                  {{ flag.text }}
                </v-col>
              </v-row>
            </v-list-item-title>
            <v-divider></v-divider>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu :nudge-width="200" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <notification-bell
              :size="25"
              :count="getAlarms.length"
              :upperLimit="50"
              counterLocation="upperRight"
              counterStyle="roundRectangle"
              counterBackgroundColor="#FF0000"
              counterTextColor="#FFFFFF"
              iconColor="#000000"
            />
          </v-btn>
        </template>
        <v-card width="400" style="max-height: 500px; overflow-y: auto">
          <v-col>
            <v-card class="text-center" v-if="getAlarms.length < 1">
              <v-list style="overflow-y: auto">
                <v-list-item>
                  <v-list-item-content>
                    <span> No Notifications </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
            </v-card>
            <div v-if="getAlarms.length > 0">
              <v-card
                v-for="(item, index) in getAlarms"
                :key="index"
                class="my-5 mx-1"
                @click="gotoStation(item.station._id)"
                style="background-color: red"
              >
                <v-row justify="center" align="center">
                  <v-col cols="2" justify="center" align="center">
                    <v-icon dark class="ml-3"> mdi-bell-alert </v-icon>
                  </v-col>
                  <v-col style="background-color: #fff">
                    <h4>{{ item.station.name }}:</h4>
                    <h5>
                      {{
                        new Date(item.datetimeStart).toLocaleString(
                          $t("dateTime")
                        )
                      }}
                    </h5>
                    <span>{{ item.alarm }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-card>
      </v-menu>

      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon> mdi-account </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ getAccess.name }}</v-list-item-title>
                <v-list-item-subtitle
                  >@{{ getAccess.username }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item @click="opendialog()">
              <v-list-item-action>
                <v-icon>mdi-key-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("Change password") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="dialogLogout = true">
              <v-list-item-action>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-dialog v-model="dialog" width="350" persistent>
      <v-card>
        <v-card-title class="info white--text">
          {{ $t("Change password") }}
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="changePassword"
            ref="changePassword"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              :type="password.hideOld ? 'text' : 'password'"
              :append-icon="
                password.hideOld ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="old password"
              id="id"
              :label="$t('Old Password')"
              hide-details="auto"
              v-model="password.old"
              @click:append="password.hideOld = !password.hideOld"
              :rules="[(v) => !!v || 'Old Password is required']"
              required
            ></v-text-field>
            <v-text-field
              :type="password.hideNew ? 'text' : 'password'"
              :append-icon="
                password.hideNew ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="new password"
              id="id"
              :label="$t('New Password')"
              hide-details="auto"
              v-model="password.new"
              @click:append="password.hideNew = !password.hideNew"
              :rules="[(v) => !!v || 'New Password is required']"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="changePassword()">Change</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLogout" persistent max-width="280">
      <v-card>
        <v-card-title> {{ $t("Logout") }} </v-card-title>
        <v-card-title>
          {{ $t("Are you sure you want to logout?") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="gray" text @click="dialogLogout = false">
            {{ $t("Cancel") }}
          </v-btn>

          <v-btn color="info" @click="logout">{{ $t("Logout") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
    <notifications group="custom-template" :style="sizeNoti()">
      <template slot="body" slot-scope="props">
        <div class="ma-5">
          <v-card :style="colorCard(props.item.type)">
            <v-row v-row justify="center" align="center" class="pl-1">
              <v-col
                cols="3"
                lg="2"
                md="2"
                sm="3"
                justify="center"
                align="center"
              >
                <v-icon dark class="ml-3"> mdi-bell-alert </v-icon>
              </v-col>

              <v-col style="background-color: #fff">
                <v-row justify="center" class="pa-0">
                  <v-col class="pb-0 pt-1">
                    <h3>{{ props.item.title }}</h3> </v-col
                  ><v-col cols="2" class="pa-0" style="background-color: #fff">
                    <v-btn icon @click="props.close">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <div v-if="props.item.type == 'error'">
                      <h5>
                        {{
                          new Date(
                            props.item.text.alarm.datetimeStart
                          ).toLocaleString($t("dateTime"))
                        }}
                      </h5>
                      <span>{{ props.item.text.alarm.alarm }}</span>
                    </div>
                    <div v-else>
                      <span>{{ props.item.text.alarm }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import NotificationBell from "vue-notification-bell";
import { VListItem, VListItemTitle } from "vuetify/lib";
export default {
  name: "NavbarComponents",
  props: {
    source: String,
  },
  data: () => ({
    isMobile: false,
    locale: "th-TH",
    dialog: false,
    dialogLogout: false,
    drawer: null,
    lang: 0,
    flags: [
      {
        text: "THA",
        value: "th-TH",
        img: require("/public/flag/THA.svg"),
      },

      {
        text: "EN",
        value: "en-US",
        img: require("/public/flag/EN.svg"),
      },
    ],
    items: [
      { icon: "mdi-home", text: "Overview", link: "/overview" },
      { icon: "mdi-youtube", text: "Knowledge", link: "/knowledges" },
      { icon: "mdi-comment", text: "Suggestion", link: "/suggestion" },
    ],
    password: {
      old: "",
      new: "",
      hideOld: false,
      hideNew: false,
    },
    default: {
      old: "",
      new: "",
      hideOld: false,
      hideNew: false,
    },
    payload: {
      id: "",
      newPassword: "",
      password: "",
    },
    snackbar: false,
    text: "",
    color: "",

    path: null,
    homePath: false,
    dataPopup: [],
  }),
  components: {
    NotificationBell,
    "v-list-item": VListItem,
    "v-list-item-title": VListItemTitle,
  },
  mounted() {
    this.$store.dispatch("auth/decode").then(() => {
      //console.log(this.getAccess);
      if (this.getAccess) {
        if (this.getAccess.role == "Admin Numpol") {
          this.items.push({
            icon: "mdi-cog",
            text: "Setting Admin",
            subLinks: [
              {
                text: "Accounts",
                to: "/accounts",
              },
              {
                text: "Stations",
                to: "/stations",
              },
              {
                text: "Sectors",
                to: "/sectors",
              },
              {
                text: "Offices",
                to: "/offices",
              },
            ],
          });
        }
        this.sockets.subscribe("notification", (data) => {
          //console.log(data)
          let type;
          if (
            this.getAccess.role != "Admin Office" ||
            this.getAccess.role != "Admin Sector"
          ) {
            if (
              data.station.sector == this.getAccess.sector ||
              this.getAccess.role == "Admin Numpol"
            ) {
              if (data.type == "alarm") {
                type = "error";
                if (data.alarm.datetimeStop == "") {
                  this.$notify({
                    group: "custom-template",
                    type: type,
                    title: data.station.name,
                    text: data,
                    duration: 10000,
                  });
                }
              } else {
                type = "success";
                this.$notify({
                  group: "custom-template",
                  type: type,
                  title: data.station.name,
                  text: data,
                  duration: 10000,
                });
              }
            }
          }
        });
      }
    });

    /*  this.sockets.subscribe("status", (data) => {
     //console.log(data)
    }); */
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getAlarms() {
      return this.$store.getters.getAlarmActive;
    },
  },
  created() {
    this.$store.dispatch("auth/decode");
    this.$store.dispatch("getAlarms");
    //this.checkManu(); */
  },
  watch: {
    $route(to) {
      console.log(to);
      /* if (
        to.name.search("companyAdmin") === 0 ||
        to.name.search("departmentAdmin") === 0 ||
        to.name.search("accountsAdmin") === 0
      ) {
        this.items = this.adminManu;
        this.homePath = false;
      } else {
        this.items = this.homeManu;
        this.homePath = true;
      } */
    },
  },
  methods: {
    checkOffice(data) {
      console.log('this.getAccess.office.name')
      console.log(data)
      if (data.office.name == "กรมชลประทาน") {
        return require("@/assets/logo/rid.png");
      } else if (data.office.name == "666159e6985e436a76f6b03c") {
        return require("@/assets/logo/dwr.png");
      } else if (data.office == "6715522295b85f383397f4e5") {
        return require("@/assets/logo/ddpm.jpg");
      } else {
        return require("@/assets/logo/logo.png");
      }
    },
    colorCard(data) {
      //console.log(data)
      switch (data) {
        case "error":
          return "background-color: red;";
        case "success":
          return "background-color: green;";
        default:
        // code block
      }
    },
    sizeNoti() {
      if (this.isMobile) {
        return "width: 300px";
      } else {
        return "width: 30vw";
      }
    },

    gotoStation(id) {
      this.$router.push({ path: "/station/status/" + id });
    },
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },

    changeLang(i) {
      //console.log(i);
      this.lang = i;
      this.$i18n.locale = this.flags[this.lang].value;
    },

    opendialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.password = Object.assign({}, this.default);
        this.$refs.changePassword.resetValidation();
      }, 300);
    },

    changePassword() {
      if (this.$refs.changePassword.validate()) {
        this.payload.id = this.getAccess.id;
        this.payload.newPassword = this.password.new;
        this.payload.password = this.password.old;
        this.$store.dispatch("auth/changePassword", this.payload).then(
          () => {
            this.dialog = false;
            this.color = "success";
            this.text = "Success! Your Password has been changed!";
            this.snackbar = true;
          },
          (error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    logout() {
      try {
        this.$store.dispatch("auth/logout").then(() => {
          this.$router.push("/");
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 8px;
}
</style>
