<template>
  <v-container fluid v-if="getStations">
    <v-row>
      <v-col cols="12" xl="7" lg="7">
        <v-card min-height="625">
          <v-row class="px-2">
            <v-spacer></v-spacer>
            <v-col align="end">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }"
                  ><v-btn v-bind="attrs" v-on="on" color="info" small outlined>
                    <v-icon left dark> mdi-filter </v-icon>
                    Filter
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-card width="300px">
                  <v-list class="pt-1">
                    <v-list-item>
                      <span> {{ $t("Search By") }} </span>
                    </v-list-item>
                    <!-- <v-list-item v-if="getAccess.role == 'Admin Numpol'">
                      <v-autocomplete
                        v-model="selectedOffices"
                        :items="getOffices"
                        :label="$t('Office')"
                        @change="searchByOffice"
                        item-text="name"
                        multiple
                        dense
                        hide-details
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ selectedOffices.length - 1 }} others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggleOffice"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  selectedOffices.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                              >
                                {{ iconOffice }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("Select All") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-list-item>
                    <v-list-item v-if="getAccess.role == 'Admin Office'">
                      <v-autocomplete
                        v-model="selectedSectors"
                        :items="getSectors"
                        :label="$t('Sector')"
                        @change="searchBySector"
                        item-text="name"
                        multiple
                        dense
                        hide-details
                        return-object
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ selectedOffices.length - 1 }} others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggleSector"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  selectedOffices.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                              >
                                {{ iconSector }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("Select All") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-list-item> -->
                    <v-list-item>
                      <v-autocomplete
                        v-model="selectedStations"
                        :items="getStations"
                        :label="$t('Stations')"
                        item-text="name"
                        multiple
                        dense
                        hide-details
                        return-object
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ selectedStations.length - 1 }} others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  selectedStations.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                              >
                                {{ iconStation }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("Select All") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <div class="pa-2">
            <CardMap
              :stations="selectedStations"
              :bngLat="lat"
              :bngLng="lng"
              :height="560"
            />
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="5" lg="5" class="pl-2">
        <v-card class="mb-8" height="200">
          <v-row class="px-3 pt-3" justify="space-between">
            <v-col justify="center">
              <span style="font-size: 1.2em">
                {{ $t("All Stations") }}
              </span>
            </v-col>
            <v-col align="end">
              <v-btn outlined small color="info" @click="dialog = true">
                {{ $t("Show All") }}
              </v-btn>
            </v-col>
          </v-row>
          <span style="font-size: 2.5em" class="pl-3">{{
            getStations.length
          }}</span>
          <v-row class="px-3 pb-6">
            <v-col cols="6" class="pb-4">
              <span class="ready">
                {{ $t("Ready") }}
                {{
                  getStations.filter((item) => item.stage === "Ready").length
                }}
              </span>
            </v-col>
            <v-col cols="6">
              <span class="working">
                {{ $t("Working") }}
                {{
                  getStations.filter((item) => item.stage === "Working").length
                }}
              </span>
            </v-col>
            <v-col cols="6">
              <span class="warningalarm">
                {{ $t("Waiting for check") }}
                {{
                  getStations.filter((item) => item.stage === "Warning Alarm")
                    .length
                }}
              </span>
            </v-col>
            <v-col cols="6">
              <span class="troble">
                {{ $t("Troble") }}
                {{
                  getStations.filter((item) => item.stage === "Shutdown Alarm")
                    .length
                }}
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-row>
            <v-col cols="12" xl="7" lg="7" md="7" sm="12" align="center">
              <iframe
                id="today"
                :src="
                  'https://tmd-dev.azurewebsites.net/TodayWeatherWidget?province=' +
                  city
                "
                height="375px"
                min-width="100%"
                scrolling="yes"
                frameBorder="0"
              ></iframe>
            </v-col>
            <v-col cols="12" xl="5" lg="5" md="5" align="center">
              <iframe
                id="7d"
                :src="
                  'https://tmd-dev.azurewebsites.net/weatherForecast7DaysWidget?province=' +
                  city
                "
                height="375px"
                width="100%"
                scrolling="yes"
                frameBorder="0"
              ></iframe>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="7" lg="7">
        <v-card class="mb-4" min-height="232">
          <v-col style="font-weight: bold">
            {{ $t("Type of pump") }}
          </v-col>
          <v-row justify="center" align="center" style="min-height: 43px">
            <v-col cols="2" xl="1" lg="1" md="1" align="center" class="py-0">
              <v-img
                width="50px"
                cover
                :src="require('../../public/img/icon/16.png')"
              ></v-img>
            </v-col>
            <v-col class="pl-0">
              <span>เครื่องสูบน้ำแบบเคลื่อนที่ระบบไฮดรอลิค ขนาด 16 นิ้ว</span>
            </v-col>
          </v-row>
          <v-row align="center" style="min-height: 43px">
            <v-col cols="2" xl="1" lg="1" md="1" align="center" class="py-0">
              <v-img
                width="50px"
                cover
                :src="require('../../public/img/icon/24.png')"
              ></v-img>
            </v-col>
            <v-col class="pl-0">
              <span>เครื่องสูบน้ำแบบเคลื่อนที่ระบบไฮดรอลิค ขนาด 24 นิ้ว</span>
            </v-col>
          </v-row>
          <v-row align="center" style="min-height: 43px">
            <v-col cols="2" xl="1" lg="1" md="1" align="center" class="py-0">
              <v-img
                width="50px"
                cover
                :src="require('../../public/img/icon/booster.png')"
              ></v-img>
            </v-col>
            <v-col class="pl-0">
              <span>ชุดไฮดรอลิคบูสเตอร์ปั๊มแบบสูบส่ง 2 หัวสูบ</span>
            </v-col>
          </v-row>
          <v-row align="center" style="min-height: 43px">
            <v-col cols="2" xl="1" lg="1" md="1" align="center" class="pt-0">
              <v-img
                width="50px"
                cover
                :src="require('../../public/img/icon/centrifugal.png')"
              ></v-img>
            </v-col>
            <v-col class="pt-0 pl-0">
              <span>
                ชุดสูบน้ำระบบไฮดรอลิคส่งระยะไกลแบบปรับอัตราการไหล
                ตามช่วงกำลังงานที่เปลี่ยนไป
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" xl="5" lg="5" class="pl-2">
        <v-card class="mb-4" min-height="232">
          <v-col cols="12" style="font-weight: bold">
            {{ $t("All Active Alarms") }}
          </v-col>
          <v-col cols="12" v-if="getAlarms.length == 0">
            {{ $t("No Alarms") }}
          </v-col>
          <v-col class="py-0" cols="12" v-else-if="getAlarms.length != 0">
            <v-virtual-scroll :items="getAlarms" :item-height="80" height="160">
              <template v-slot:default="{ item }">
                <v-list-item
                  :key="item._id"
                  @click="gotoStation(item.station._id)"
                >
                  <v-list-item-action>
                    <v-icon color="error"> mdi-bell </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.station.name }}
                      <br />
                      {{
                        new Date(item.datetimeStart).toLocaleString(
                          $t("dateTime")
                        )
                      }}
                      <br />
                      {{ item.alarm }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
        <v-card-title class="text-center"
          >{{ $t("All Stations") }}
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text
          ><v-col cols="12">
            <v-text-field
              :label="$t('Search')"
              dense
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="getStations.length == 0">
            {{ $t("No Stations") }}
          </v-col>
          <v-col class="py-0" cols="12" v-else-if="getStations.length != 0">
            <v-virtual-scroll
              :items="filteredList()"
              :item-height="80"
              height="300"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item._id" @click="toStation(item)">
                  <v-list-item-action>
                    <v-icon
                      v-if="item.statusOnline == 'Online'"
                      color="#078847"
                    >
                      mdi-wifi
                    </v-icon>
                    <v-icon v-else color="error"> mdi-wifi-off </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <br />
                      {{ item.statusOnline }}
                      <br />
                      {{ $t(item.stage) }}
                      <br />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            {{ $t("Close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//import { useGeolocation } from "@vueuse/core";

import CardMap from "../components/Overview/CardMap.vue";

export default {
  name: "OverviewPage",
  data() {
    return {
      dialog: false,
      city: null,
      showAll: false,
      message: "",
      messages: [],
      lat: 14,
      lng: 100,
      search: "",
      selectedSectors: [],
      selectedStations: [],
      selectedOffices: [],
      searchStage: "",
    };
  },
  computed: {
    /* searchStations: function () {
      return this.selectedStations.filter((station) => {
        if(station.stage == "All"){
          if(station.sector == "All") {
            if(station.office != "All"){
              return (station.office._id.match(this.searchOffice))
            }
          } else {

          }
        }
        return (
          station.stage.match(this.searchStage) || station.sector._id.match(this.searchSector) || station.office._id.match(this.searchOffice)
        )
      })
    }, */
    getSectors() {
      return this.$store.state.allSectors;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getStations() {
      return this.$store.getters.allStations;
    },
    getOffices() {
      return this.$store.getters.allOffices;
    },
    getAlarms() {
      return this.$store.getters.getAlarmActive;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    allOffice() {
      return this.selectedOffices.length === this.getOffices.length;
    },
    allSector() {
      return this.selectedSectors.length === this.getSectors.length;
    },
    allStation() {
      return this.selectedStations.length === this.getStations.length;
    },
    someOffice() {
      return this.selectedOffices.length > 0 && !this.allOffice;
    },
    someSector() {
      return this.selectedSectors.length > 0 && !this.allSector;
    },
    someStation() {
      return this.selectedStations.length > 0 && !this.allStation;
    },
    iconStation() {
      if (this.allStation) return "mdi-close-box";
      if (this.someStation) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconOffice() {
      if (this.allOffice) return "mdi-close-box";
      if (this.someStation) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconSector() {
      if (this.allSector) return "mdi-close-box";
      if (this.someStation) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  created() {
    console.log(this.loggedIn);
    if (!this.loggedIn) {
      this.$router.push("/");
    }
  },
  components: {
    CardMap,
  },
  mounted() {
    //console.log(process.env.VUE_APP_PUBLIC_BASE_URL);
    this.$store.dispatch("getOffices").then(() => {
      this.selectedOffices = this.getOffices;
    });
    this.$store.dispatch("getSectors").then(() => {
      this.selectedSectors = this.getSectors;
    });
    this.$store.dispatch("getStations").then(() => {
      this.toggle();
    });
    if (navigator.geolocation) {
      //console.log("ok");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.lat},${this.lng}&key=${process.env.VUE_APP_MAPS_API_KEY}`;
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              let getcity = data.results[0].address_components.find(
                (component) =>
                  component.types.includes("administrative_area_level_1")
              ).short_name;
              getcity = getcity.split("จ.");
              this.city = getcity[1];
              console.log(this.city);
            })
            .catch((error) => console.log(error)); //console.log(position.coords);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("your brower dose not support");
    }
  },

  methods: {
    searchBySector() {
      
    },
    searchByOffice() {
      let result = this.getStations.map((o) =>
        this.selectedOffices.some((slot) => slot === o.office.name)
      );
      this.selectedStations = result;
      console.log(result);
    },
    gotoStation(id) {
      this.$router.push({ path: "/station/status/" + id });
    },
    filteredList() {
      return this.getStations.filter((post) => {
        return post.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    toStation(s) {
      this.$router.push({ path: "/station/status/" + s.id });
    },
    toggleSector() {
      this.$nextTick(() => {
        if (this.allSector) {
          this.selectedSectors = [];
        } else {
          this.selectedSectors = this.getSectors.slice();
        }
      });
    },
    toggleOffice() {
      this.$nextTick(() => {
        if (this.allOffice) {
          this.selectedOffices = [];
        } else {
          this.selectedOffices = this.getOffices.slice();
        }
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allStation) {
          this.selectedStations = [];
        } else {
          this.selectedStations = this.getStations.slice();
        }
      });
    },
  },
};
</script>
<style scoped>
.ready {
  padding-left: 1em;
  position: absolute;
}
.ready:before {
  background-color: #013d81;
  border-radius: 8px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
.warningalarm {
  padding-left: 1em;
  position: absolute;
}
.warningalarm:before {
  background-color: #f48010;
  border-radius: 8px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
.troble {
  padding-left: 1em;
  position: absolute;
}
.troble:before {
  background-color: #e8090d;
  border-radius: 8px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
.working {
  padding-left: 1em;
  position: absolute;
}
.working:before {
  background-color: #078847;
  border-radius: 8px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}
</style>
