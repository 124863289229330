<template>
  <div>
    <v-bottom-navigation
      dense
      fixed
      color="info"
      background-color="#F8F8FA"
      v-model="path"
    >
      <v-btn
        style="width: 80px"
        v-for="item of items"
        :key="item.id"
        :to="item.to"
        :value="item.name"
      >
        <span style="font-size: xx-small">{{ item.name }}</span>

        <v-icon style="font-size: x-large">{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  mounted() {
    this.checkManu();
  },
  watch: {
    $route(to) {
      console.log(to.name);
      this.path = to.name;
      this.checkManu();
    },
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data: () => ({
    tab: null,
    path: null,
    itemsHome: [
      { icon: "mdi-home", name: "Overview", to: "/overview" },
      { icon: "mdi-youtube", name: "Knowledge", to: "/knowledges" },
      { icon: "mdi-comment", name: "Suggestion", to: "/suggestion" },
    ],
    items: [],
    itemsAdmin: [
      { name: "Overview", to: "/overview", icon: "mdi-home" },
      { icon: "mdi-youtube", name: "Knowledge", to: "/knowledges" },
      { icon: "mdi-comment", name: "Suggestion", to: "/suggestion" },
      { name: "Setting", to: "/accounts", icon: "mdi-cog" },
    ],
    itemsSettingAdmin: [
      { name: "Overview", to: "/overview", icon: "mdi-home" },
      { name: "Accounts", to: "/accounts", icon: "mdi-account" },
      { name: "Stations", to: "/stations", icon: "mdi-pump" },
      { name: "Sectors", to: "/sectors", icon: "mdi-account-group" },
      { name: "Offices", to: "/offices", icon: "mdi-office-building" },
    ],
  }),
  methods: {
    checkManu() {
      //console.log(this.eventSound);
      this.path = this.$router.currentRoute.name;
      console.log(this.path);
      if (
        this.path == "Stations" ||
        this.path == "Sectors" ||
        this.path == "Accounts" ||
        this.path == "Offices"
      ) {
        if (this.getAccess.role == "Admin Numpol") {
          this.items = this.itemsSettingAdmin;
        } else {
          this.$router.push("/overview");
        }
      } else if (
        this.path == "Overview" ||
        this.path == "Station" ||
        this.path == "Knowledges" ||
        this.path == "Suggestion"
      ) {
        if (this.getAccess.role == "Admin Numpol") {
          this.items = this.itemsAdmin;
        } else {
          this.items = this.itemsHome;
        }
      }
    },
  },
};
</script>

<style>
.v-bottom-navigation {
  justify-content: start;
}
</style>
