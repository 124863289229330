import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "alarms/";
import authHeader from "../services/auth-header";
//import authHeader from "../services/auth-header";
export const alarms = {
  state: {
    alarms: [],
    alarmsActive: [],
    alarmStation: [],
    alarmsActiveStation: [],
  },
  getters: {
    allAlarms: (state) => state.alarms,
    getAlarmActive: (state) => state.alarmsActive,
    getAlarmStation: (state) => state.alarmStation,
    getAlarmActiveStion: (state) => state.alarmsActiveStation,
  },
  mutations: {
    SOCKET_notification(state, data) {
      
      if (
        data.station.sector == this.state.auth.access.sector ||
        this.state.auth.access.role == "Admin Numpol" ||
        (this.state.auth.access.role == "Admin Office" &&
          data.station.office == this.state.auth.access.office)
      ) {
        if (data.type == "alarm") {
          //console.log("alarm")
          if (data.alarm.datetimeStop == "") {
            //console.log("new")
            if (this.state.stations.station._id == data.station._id) {
              state.alarmsActiveStation.push(data.alarm);
            }
            state.alarmsActive.push(data.alarm);
          } else {
            //console.log("end")
            let alarmsActive = state.alarmsActive;
            let alarmsActiveStation = state.alarmsActiveStation;
            let filteralarmsActive = alarmsActive.filter(
              (element) => element._id !== data.alarm._id
            );
            let filteralarmsActiveStation = alarmsActiveStation.filter(
              (element) => element._id !== data.alarm._id
            );
            state.alarmsActiveStation = filteralarmsActiveStation;
            state.alarmsActive = filteralarmsActive;
          }
        }
        if (this.state.stations.station != null) {
          if (this.state.stations.station._id == data.station._id) {
            this.state.stations.station.pump = data.station.pump;
            this.state.stations.station.engine = data.station.engine;
            this.state.stations.station.stage = data.station.stage;
          }
        }
        if (this.state.stations.stations.length != 0) {
          let index = this.state.stations.stations.findIndex(
            (s) => s._id === data.station._id
          );
          if (index > -1) {
            this.state.stations.stations[index].pump = data.station.pump;
            this.state.stations.stations[index].engine = data.station.engine;
            this.state.stations.stations[index].stage = data.station.stage;
          }
        }
      }
      //console.log(state)
    },
    setAlarms(state, alarms) {
      let alarmsActive = [];
      state.alarms = alarms;
      alarms.forEach((alarm) => {
        if (alarm.datetimeStop == "") {
          alarmsActive.push(alarm);
        }
      });
      //console.log(alarmsActive);
      state.alarmsActive = alarmsActive;
    },
    setAlarmStation(state, alarms) {
      state.alarmStation = alarms;
    },
    setAlarmActive(state, alarms) {
      state.alarmsActiveStation = alarms;
    },
  },
  actions: {
    async getAlarms(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          
          context.commit("setAlarms", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    async searchAlarm(context, data) {
      
      return axios
        .post(apiURL + "search", data, { headers: authHeader() })
        .then((res) => {
          
          context.commit("setAlarmStation", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    async searchAciveAlarm(context, id) {
      return axios
        .get(apiURL + "search/active/" + id, { headers: authHeader() })
        .then((res) => {
          
          context.commit("setAlarmActive", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
  },
};
