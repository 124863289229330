<template>
  <div id="app">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <h2>{{ $t("Knowledge") }}</h2>
        </v-col>
        <!-- <v-row>
          <v-col v-for="k in getCategories" :key="k._id"> </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="3" v-for="k in getKnowledges" :key="k._id">
            <v-card :href="`/knowledges/${k._id}`" max-width="344" >
              <v-img
                :src="require('/public/img/no-photo.jpg')"
                height="200px"
              ></v-img>
              <v-card-title class="text--center">
                <h5>
                  {{ k.mainLink }}
                </h5>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
      <v-btn
        class="my-10"
        fixed
        :style="isMobile ? 'bottom: 30px;' : 'bottom: -20px;'"
        fab
        color="info"
        dark
        absolute
        bottom
        right
        href="/knowledges/add/"
        v-if="getAccess.role == 'Admin Numpol'"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";

export default {
  name: "KnowledgesPage",
  components: {},

  computed: {
    getKnowledges() {
      return this.$store.getters.allArticles;
    },
    getCategories() {
      return this.$store.getters.allCategories;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    console.log(this.loggedIn);
    if (!this.loggedIn) {
      this.$router.push("/");
    }
  },
  mounted() {
    //console.log(process.env.VUE_APP_PUBLIC_BASE_URL);
    this.$store.dispatch("getArticles");
  },
  data() {
    return {
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      content: "",
      dialog: false,
    };
  },
};
</script>
