<template>
  <div>
    <GmapMap
      :center="{ lat: bngLat, lng: bngLng }"
      :zoom="7"
      map-type-id="terrain"
      :style="{ width: '100%', height: `${height}` + 'px' }"
    >
      <GmapMarker
        :key="s._id"
        v-for="s in stations"
        :position="s.GPS"
        :clickable="true"
        :icon="{
          url: require(`../../../public/img/icon/${s.type.name}.png`),
          size: { width: 60, height: 48, f: 'px', b: 'px' },
          scaledSize: { width: 60, height: 48, f: 'px', b: 'px' },
        }"
        @click="toStatus(s)"
      >
        <GmapInfoWindow :key="s.name" :position="s.GPS">
          {{ s.name }}
        </GmapInfoWindow>
        <GmapCircle
          :key="s._id"
          :center="s.GPS"
          :radius="7000"
          :visible="true"
          :options="{
            strokeColor: `${colorStation(s.stage)}`,
            strokeOpacity: 0.05,
            strokeWeight: 10,
            fillColor: `${colorStation(s.stage)}`,
            fillOpacity: 0.05,
          }"
        ></GmapCircle>
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: ["stations", "bngLat", "bngLng", "height"],
  mounted() {
    console.log(this.stations);
  },
  methods: {
    colorStation(stage) {
      switch (stage) {
        case "Ready":
          return "#013D81";
        case "Working":
          return "#078847";
        case "Warning Alarm":
          return "#f48010";
        case "Shutdown Alarm":
          return "#FF0000";
      }
    },
    toStatus(s) {
      this.$router.push({ path: "/station/status/" + s.id });
    },
  },
};
</script>

<style lang="scss" scoped></style>
