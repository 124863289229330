<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-title>
        <h3>{{ $t("Accounts") }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="#2C3A47"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="getUsers"
        :search="search"
      >
        <template v-slot:[`header.name`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:[`header.role`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:[`header.reset`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:[`header.actives`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:[`item.username`]="{ item }">
          @{{ item.username }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            class="mx-2"
            fab
            x-small
            @click="editUser(item)"
            outlined
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="pink"
            fab
            x-small
            @click="deleteUser(item._id)"
            outlined
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.reset`]="{ item }">
          <v-btn
            color="indigo accent-4"
            class="mx-2"
            fab
            x-small
            @click="resetPassword(item._id)"
            outlined
          >
            <v-icon small>mdi-lock</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.actives`]="{ item }">
          <v-simple-checkbox
            :ripple="false"
            v-model="item.active"
            @click="checkboxUpdated(item)"
          ></v-simple-checkbox>
        </template>

        <template v-slot:no-results>
          <v-alert :value="true" color="pink" icon="warning" dark>
            Your Search {{ search }} does not exist.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 my-10"
      fixed
      :style="isMobile ? 'bottom: 50px;' : 'bottom: -20px;'"
      fab
      color="info"
      dark
      absolute
      bottom
      right
      @click="
        close();
        opendialog();
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div id="modal">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title v-if="create" class="headline info white--text">
            Add User
          </v-card-title>
          <v-card-title v-else class="headline info white--text">
            Edit User
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="savePost"
              ref="userData"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                name="name"
                id="id"
                label="Name"
                hide-details="auto"
                v-model="userData.name"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
              <v-text-field
                :disabled="!create"
                name="username"
                id="username"
                label="Username"
                hide-details="auto"
                v-model="userData.username"
                :rules="[(v) => !!v || 'Username is required']"
                required
              ></v-text-field>
              <v-text-field
                v-if="create"
                :type="hidePassword ? 'password' : 'text'"
                :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'"
                name="password"
                id="password"
                label="Password"
                hide-details="auto"
                v-model="userData.password"
                @click:append="hidePassword = !hidePassword"
                :rules="[(v) => !!v || 'Password is required']"
                required
              ></v-text-field>
              <v-select
                :items="[
                  'Technician',
                  'Engineer',
                  'Admin Sector',
                  'Admin Office',
                  'Admin Numpol',
                ]"
                v-model="userData.role"
                :rules="[(v) => !!v || 'Role is required']"
                @change="changeRole"
                required
                label="Role"
                id="role"
                :item-text="item"
              ></v-select>
              <v-select
                :items="getOffices"
                id="office"
                item-text="name"
                item-value="_id"
                label="Office"
                v-model="userData.office"
                :rules="[(v) => !!v || 'Company is required']"
                required
              ></v-select>
              <v-select
                :items="getSectors"
                id="sector"
                item-text="name"
                item-value="_id"
                label="Sector"
                v-model="userData.sector"
                :rules="[(v) => !!v || 'Company is required']"
                required
              ></v-select>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="success" text @click="savePost()">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div id="modalReset">
      <v-dialog v-model="dialogReset" width="500" persistent>
        <v-card>
          <v-card-title class="headline info white--text">
            Reset Password
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="resetPasswordConfirm"
              ref="resetPass"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                :type="hideNewPassword ? 'password' : 'text'"
                :append-icon="
                  hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="new password"
                id="id"
                label="New Password"
                hide-details="auto"
                v-model="password.new"
                @click:append="hideNewPassword = !hideNewPassword"
                :rules="[(v) => !!v || 'New Password is required']"
                required
              ></v-text-field>
              <v-text-field
                :type="hideConfirmPassword ? 'password' : 'text'"
                :append-icon="
                  hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="confirm password"
                id="id"
                label="Confirm Password"
                hide-details="auto"
                v-model="password.confirm"
                @click:append="hideConfirmPassword = !hideConfirmPassword"
                :rules="[(v) => !!v || 'Confirm Password is required']"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="success" text @click="resetPasswordConfirm()"
              >Save</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeModalReset"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div id="modalChange">
      <v-dialog v-model="dialogChange" width="500" persistent>
        <v-card>
          <v-card-title class="headline info white--text">
            Change Password
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="changePasswordConfirm"
              ref="changePass"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                :type="hideOldChangePassword ? 'password' : 'text'"
                :append-icon="
                  hideOldChangePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="password"
                id="changePassOld"
                label="Password"
                hide-details="auto"
                v-model="changePasswordData.old"
                @click:append="hideOldChangePassword = !hideOldChangePassword"
                :rules="[(v) => !!v || 'Password is required']"
                required
              ></v-text-field>
              <v-text-field
                :type="hideNewChangePassword ? 'password' : 'text'"
                :append-icon="
                  hideNewChangePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="new password"
                id="changePassNew"
                label="New Password"
                hide-details="auto"
                v-model="changePasswordData.new"
                @click:append="hideNewChangePassword = !hideNewChangePassword"
                :rules="[(v) => !!v || 'New Password is required']"
                required
              ></v-text-field>
              <v-text-field
                :type="hideConfirmChangePassword ? 'password' : 'text'"
                :append-icon="
                  hideConfirmChangePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="confirm password"
                id="changePassConfirm"
                label="Confirm Password"
                hide-details="auto"
                v-model="changePasswordData.confirm"
                @click:append="
                  hideConfirmChangePassword = !hideConfirmChangePassword
                "
                :rules="[(v) => !!v || 'Confirm Password is required']"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="success" text @click="changePasswordConfirm()"
              >Save</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeModalChange">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//import axios from "axios";
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";
export default {
  name: "AccountPage",
  components: {},
  computed: {
    getUsers() {
      let alluser = this.$store.getters.allUsers;
      let filterUser = alluser.filter((element) => element.name !== "pmAdmin");
      return filterUser;
    },
    getOffices() {
      return this.$store.getters.allOffices;
    },
    getSectors() {
      return this.$store.getters.allSectors;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (!this.loggedIn) {
      this.$router.push("/");
    }
    //console.log(this.getUsers);
    this.loadUsers();
  },
  data() {
    return {
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      changePassword: false,
      create: true,
      mini: false,
      dialog: false,
      dialogReset: false,
      dialogChange: false,
      fab: false,
      hidePassword: true,
      hideConfirmPassword: true,
      hideNewPassword: true,
      hideChangePassword: true,
      hideConfirmChangePassword: true,
      hideNewChangePassword: true,
      hideOldChangePassword: true,
      search: "",
      snackbar: false,
      text: "",
      color: "",
      btnName: "",
      users: [],
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Username", value: "username", sortable: true },
        { text: "Role", value: "role", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
        {
          text: "Reset Password",
          value: "reset",
          sortable: false,
          align: "center",
        },
        { text: "Active", value: "actives", sortable: false },
      ],
      userData: {
        name: "",
        username: "",
        role: "",
        office: "",
        sector: "",
        password: "",
        newPassword: "",
      },
      default: {
        name: "",
        username: "",
        role: "",
        office: "",
        sector: "",
        password: "",
        newPassword: "",
      },
      editedIndex: -1,
      password: {
        new: "",
        confirm: "",
        id: "",
      },
      changePasswordData: {
        old: "",
        new: "",
        confirm: "",
      },
      payload: {
        id: "",
        password: "",
        newPassword: "",
        active: true,
      },
      departmetEnable: false,
      selectAdmin: false,
    };
  },
  methods: {
    opendialog() {
      this.dialog = true;
      this.create = true;
      this.changePassword = false;
      this.selectAdmin = false;
    },
    loadUsers() {
      this.$store.dispatch("getUsers").then((res) => {
        console.log(res);
        if (res.status != "200") {
          this.color = "error";
          this.text = res.data.error.message;
          this.snackbar = true;
        }
      });
      this.$store.dispatch("getOffices");
      this.$store.dispatch("getSectors");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.userData = Object.assign({}, this.default);
        this.editedIndex = -1;
        this.changePassword = false;
        this.create = true;
        this.$refs.userData.resetValidation();
        this.departmetEnable = false;
      }, 300);
    },
    savePost() {
      if (this.editedIndex > -1) {
        this.updateUser();
      } else {
        this.createUser();
      }
    },
    createUser() {
      // if (this.$refs.userData.validate()) {

      this.$store.dispatch("createUsers", this.userData).then(
        (res) => {
          console.log(res);
          if (res.status != "201") {
            this.color = "error";
            if (res.data.message == undefined) {
              this.text = res.data.error;
            } else {
              this.text = res.data.message;
            }

            this.snackbar = true;
          } else {
            this.dialog = false;
            this.color = "success";
            this.text = "User has been successfully saved.";
            this.snackbar = true;
          }
        },
        (error) => {
          this.color = "error";
          this.text = error.response.data.error.message;
          this.snackbar = true;
        }
      );
      // }
    },
    editUser(item) {
      this.editedIndex = this.getUsers.indexOf(item);
      this.userData = Object.assign({}, item);
      this.create = false;
      this.dialog = true;

      this.changeRole(this.userData.role);
    },
    updateUser() {
      if (
        this.userData.password == undefined ||
        this.userData.newPassword == undefined
      ) {
        this.userData.password = "";
        this.userData.newPassword = "";
      }
      this.$store.dispatch("editUsers", this.userData).then(
        () => {
          this.close();

          this.color = "info";
          this.text = "User has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    deleteUser(id) {
      //console.log(id);
      this.$swal({
        title: "Are you sure?",
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            this.$store.dispatch("deleteUsers", id);
          } catch (error) {
            console.log(error);
          }
          this.$swal("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    resetPassword(id) {
      this.password.id = id;
      this.dialogReset = true;
    },
    changePasswordModal() {
      this.dialogChange = true;
    },
    resetPasswordConfirm() {
      if (this.password.new == this.password.confirm) {
        this.payload.id = this.password.id;
        this.payload.newPassword = this.password.new;
        this.$store.dispatch("resetPassword", this.payload).then(
          () => {
            this.closeModalReset;
            this.color = "info";
            this.text = "Reset Password Complete.";
            this.snackbar = true;
          },
          (error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
      } else {
        this.color = "red";
        this.text = "New Password and Confirm Password do not match";
        this.snackbar = true;

        return;
      }
    },
    changePasswordConfirm() {
      if (this.changePasswordData.new == this.changePasswordData.confirm) {
        this.payload.password = this.changePasswordData.old;
        this.payload.newPassword = this.changePasswordData.new;
        this.$store.dispatch("changePassword", this.payload).then(
          () => {
            this.closeModalChange;
            this.color = "info";
            this.text = "Change Password Complete.";
            this.snackbar = true;
          },
          (error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
        this.dialogChange = false;
        setTimeout(() => {
          this.changePasswordData.id = "";
          this.changePasswordData.new = "";
          this.changePasswordData.confirm = "";
        }, 300);
      } else {
        this.color = "red";
        this.text = "New Password and Confirm Password do not match";
        this.snackbar = true;

        return;
      }
    },
    closeModalReset() {
      this.dialogReset = false;
      setTimeout(() => {
        this.changePasswordData.id = "";
        this.changePasswordData.new = "";
        this.changePasswordData.confirm = "";
      }, 300);
    },
    closeModalChange() {
      this.dialogChange = false;
      setTimeout(() => {
        this.password.id = "";
        this.password.new = "";
        this.password.confirm = "";
      }, 300);
    },
    checkboxUpdated(item) {
      console.log(item);
      this.payload.id = item._id;
      this.payload.active = item.active;
      this.$store.dispatch("activeUser", this.payload).then(
        () => {
          if (this.payload.active) {
            this.text = "Account Active Complete.";
          } else {
            this.text = "Account Not Active Complete.";
          }
          this.closeModalReset;
          this.color = "info";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    changeRole(item) {
      if (item == "admin") {
        this.selectAdmin = false;
      } else {
        this.selectAdmin = true;
      }
    },
  },
};
</script>
<style>
.swal2-container {
  font-family: "Kanit";
}
</style>
