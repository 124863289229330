import Router from "../router/index";
import Cookies from "js-cookie";
import VueJwtDecode from "vue-jwt-decode";
function removeStorage(name) {
  try {
    Cookies.remove(name);
    Router.push("/");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        name +
        "] from cookies: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
export default function getStorage(key) {
  //console.log(key)
  var now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  //var expiresIn = localStorage.getItem(key + "_expiresIn");
  let obj = {};
  let accessToken = JSON.parse(Cookies.get(key));

  if (accessToken == undefined) {
    obj.exp = 0;
  } else {
    obj = accessToken;
    let data = VueJwtDecode.decode(obj.access_token);
    obj = data;
  }
  if (obj.exp === undefined || obj.exp === null) {
    obj.exp = 0;
  }
  let expiresIn = obj.exp * 1000;
  if (expiresIn < now) {
    removeStorage(key);
    return null;
  } else {
    try {
      return accessToken;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from cookie: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
