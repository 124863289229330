var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{style:({ width: '100%', height: `${_vm.height}` + 'px' }),attrs:{"center":{ lat: _vm.bngLat, lng: _vm.bngLng },"zoom":7,"map-type-id":"terrain"}},_vm._l((_vm.stations),function(s){return _c('GmapMarker',{key:s._id,attrs:{"position":s.GPS,"clickable":true,"icon":{
        url: require(`../../../public/img/icon/${s.type.name}.png`),
        size: { width: 60, height: 48, f: 'px', b: 'px' },
        scaledSize: { width: 60, height: 48, f: 'px', b: 'px' },
      }},on:{"click":function($event){return _vm.toStatus(s)}}},[_c('GmapInfoWindow',{key:s.name,attrs:{"position":s.GPS}},[_vm._v(" "+_vm._s(s.name)+" ")]),_c('GmapCircle',{key:s._id,attrs:{"center":s.GPS,"radius":7000,"visible":true,"options":{
          strokeColor: `${_vm.colorStation(s.stage)}`,
          strokeOpacity: 0.05,
          strokeWeight: 10,
          fillColor: `${_vm.colorStation(s.stage)}`,
          fillOpacity: 0.05,
        }}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }