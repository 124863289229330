<template>
  <div id="app">
    <v-container>
      <v-col cols="12">
        <h2>{{ $t("Add new knowledge") }}</h2>
      </v-col>
      <vue-editor
        :customModules="customModulesForEditor"
        :editorOptions="editorSettings"
        v-model="content"
      ></vue-editor>
      <!-- <div v-html="content"></div> -->
      <v-col align="end">
        <v-btn width="100px" color="success" class="mr-4" @click="addKnowledge">
          {{ $t("Save") }}
        </v-btn>
        <v-btn width="100px" outlined color="#636363" href="/knowledges">
          {{ $t("cancel") }}
        </v-btn>
      </v-col>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card>
          <v-toolbar flat dark color="info">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("Add new knowledge") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialog = false">{{ $t("save") }}</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-5">
            <h2>Add Image Cover</h2>
            <h2>Add Title</h2>

            <vue-editor
              :customModules="customModulesForEditor"
              :editorOptions="editorSettings"
              v-model="content"
            ></vue-editor>
          </v-card-text>
          <div style="flex: 1 1 auto"></div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";
import ResizeModule from "@ssumo/quill-resize-module";
Quill.register("modules/resize", ResizeModule);

export default {
  name: "AddKnowledgesPage",
  components: {
    VueEditor,
  },
  data() {
    return {
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      content: "",
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "ResizeModule", module: ResizeModule },
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          ResizeModule: {
            locale: {
              // change them depending on your language
              altTip: "Hold down the alt key to zoom",
              floatLeft: "ซ้าย",
              floatRight: "ขวา",
              center: "กึ่งกลาง",
              restore: "ค่าเริ่มต้น",
            },
          },
        },
      },
      dialog: false,
    };
  },
  methods:{
    addKnowledge() {
      console.log(this.content)
    }
  }
};
</script>
