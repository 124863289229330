<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div v-if="!loading" v-html="getArticle.html"></div>
      </v-col>
      <!--  -->
    </v-row>
  </v-container>
</template>

<script>
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";

export default {
  name: "AddKnowledgesPage",

  computed: {
    getArticle() {
      console.log(this.$store.getters.oneArticle);
      return this.$store.getters.oneArticle;
    },
  },
  mounted() {
    this.$store.dispatch("getArticle", this.$route.params.id).then(() => {
      this.loading = false;
      //console.log(res);;
    });
  },
  data() {
    return {
      loading: true,
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      content: "",

      editorSettings: {
        modules: {
          imageDrop: true,
          ResizeModule: {
            locale: {
              // change them depending on your language
              altTip: "Hold down the alt key to zoom",
              floatLeft: "ซ้าย",
              floatRight: "ขวา",
              center: "กึ่งกลาง",
              restore: "ค่าเริ่มต้น",
            },
          },
        },
      },
      dialog: false,
    };
  },
  methods: {
    addKnowledge() {
      console.log(this.content);
    },
  },
};
</script>
